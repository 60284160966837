import {officesConstants} from './offices.constants';

const initialState = {offices: null, office: null, userOffice: null, loading: false, error: null, officesAvailables: null}

export function offices(state = initialState, action) {
    switch (action.type) {
        case officesConstants.OFFICES_FETCH_REQUEST:
            return {
                ...state,
                userOffice: state.userOffice,
                offices: state.offices,
                office: null,
                loading: true,
                error: null
            };
        case officesConstants.OFFICES_FETCH_SUCCESS:
            return {
                ...state,
                userOffice: state.userOffice,
                offices: action.offices,
                office: null,
                loading: false,
                error: null,
            };
        case officesConstants.OFFICES_FETCH_FAILURE:
            return {
                ...state,
                userOffice: state.userOffice,
                offices: state.offices,
                office: null,
                loading: false,
                error: action.error
            };
        case officesConstants.OFFICESAVAILABLE_FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case officesConstants.OFFICESAVAILABLE_FETCH_SUCCESS:
            return {
                ...state,
                userOffice: state.userOffice,
                office: action.office,
                offices: state.offices,
                officesAvailables: action.officesAvailables,
                loading: false,
                error: null
            };
        case officesConstants.OFFICESAVAILABLE_FETCH_FAILURE:
            return {
                ...state,
                userOffice: state.userOffice,
                office: state.office,
                offices: state.offices,
                officesAvailables: state.officesAvailables,
                loading: false,
                error: action.error
            };
        case officesConstants.OFFICES_FETCH_ONE_REQUEST:
            return {
                ...state,
                userOffice: state.userOffice,
                office: state.office,
                offices: state.offices,
                loading: true,
                error: null
            };
        case officesConstants.OFFICES_FETCH_ONE_SUCCESS:
            return {
                ...state,
                userOffice: state.userOffice,
                office: action.office,
                offices: state.offices,
                loading: false,
                error: null
            };
        case officesConstants.OFFICES_FETCH_ONE_FAILURE:
            return {
                ...state,
                userOffice: state.userOffice,
                office: state.office,
                offices: state.offices,
                loading: false,
                error: action.error
            };
        case officesConstants.OFFICES_FETCH_USER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case officesConstants.OFFICES_FETCH_USER_SUCCESS:
            return {
                ...state,
                userOffice: action.userOffice,
                office: state.office,
                offices: state.offices,
                loading: false,
                error: null
            };
        case officesConstants.OFFICES_FETCH_USER_FAILURE:
            return {
                ...state,
                userOffice: state.userOffice,
                office: state.office,
                offices: state.offices,
                loading: false,
                error: action.error
            };
        case officesConstants.OFFICES_DELETE_REQUEST:
            return {
                ...state,
                userOffice: state.userOffice,
                offices: null,
                office: state.office,
                loading: true,
                error: null
            };
        case officesConstants.OFFICES_DELETE_SUCCESS:
        case officesConstants.OFFICES_CREATE_SUCCESS:
        case officesConstants.OFFICES_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                offices: null,
                office: state.office,
                userOffice: null,
            };
        case officesConstants.OFFICES_DELETE_FAILURE:
            return {
                ...state,
                offices: null,
                office: state.office,
                userOffice: state.userOffice,
                loading: false,
                error: action.error,
            };
        default:
            return state
    }
}
