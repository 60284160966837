export const themesConstants = {
    THEMES_FETCH_REQUEST: "THEMES_FETCH_REQUEST",
    THEMES_FETCH_SUCCESS: "THEMES_FETCH_SUCCESS",
    THEMES_FETCH_FAILURE: "THEMES_FETCH_FAILURE",

    THEMES_FETCH_ONE_REQUEST: "THEMES_FETCH_ONE_REQUEST",
    THEMES_FETCH_ONE_SUCCESS: "THEMES_FETCH_ONE_SUCCESS",
    THEMES_FETCH_ONE_FAILURE: "THEMES_FETCH_ONE_FAILURE",

    THEMES_CREATE_REQUEST: "THEMES_CREATE_REQUEST",
    THEMES_CREATE_SUCCESS: "THEMES_CREATE_SUCCESS",
    THEMES_CREATE_FAILURE: "THEMES_CREATE_FAILURE",

    THEMES_UPDATE_REQUEST: "THEMES_UPDATE_REQUEST",
    THEMES_UPDATE_SUCCESS: "THEMES_UPDATE_SUCCESS",
    THEMES_UPDATE_FAILURE: "THEMES_UPDATE_FAILURE",

    THEMES_DELETE_REQUEST: "THEMES_DELETE_REQUEST",
    THEMES_DELETE_SUCCESS: "THEMES_DELETE_SUCCESS",
    THEMES_DELETE_FAILURE: "THEMES_DELETE_FAILURE",


};
