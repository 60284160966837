import {actors} from "../dictionary/actors/actors.mockup"

export const offices = [
    {
        id: 1,
        name: "PIMMS Dijon",
        siret: 430429894,
        isFranceService: true,
        address: {
            building: "Batiment B",
            type: "Rue",
            number: "26",
            name: "Grande",
            bp: "",
            postalcode: "21000",
            city: "Dijon",
            department: 21,
            region: "Bourgogne Franche-Comté",
        },
        children: [
            {
                id: 10,
                name: "Ateliers MDE",
                siret: "",
                isFranceService: true,
                address: {
                    building: "",
                    type: "Rue",
                    number: "12",
                    name: "de l'atelier",
                    bp: "",
                    postalcode: "21000",
                    city: "Dijon",
                    department: 21,
                    region: "Bourgogne Franche-Comté",
                },
                places: ["Dijon"],
                districts: []
            },
            {
                id: 11,
                name: "PE BOURROCHES",
                siret: "",
                isFranceService: true,
                address: {
                    building: "",
                    type: "Rue ",
                    number: "12",
                    name: "des Bourroches",
                    bp: "",
                    postalcode: "21000",
                    city: "Dijon",
                    department: 21,
                    region: "Bourgogne Franche-Comté"
                },
                places: ["Agglomération", "Asniere les dijon", "Bourroches", "Centre ville", "Chenove", "Cote d'or", "Daix", "Dijon", "Fontaine d'ouche"],
                districts: ["Fontaine les dijons", "Gresilles", "Mansart"]
            },
            {
                id: 12,
                name: "PE EPISOURIRE",
                siret: "",
                isFranceService: true,
                address: {
                    building: "",
                    type: "Rue ",
                    number: "12",
                    name: "du sourire",
                    bp: "",
                    postalcode: "21000",
                    city: "Dijon",
                    department: 21,
                    region: "Bourgogne Franche-Comté",
                },
                places: ["Agglomération", "Asniere les dijon", "Bourroches", "Centre ville", "Chenove", "Cote d'or", "Daix", "Dijon", "Fontaine d'ouche"],
                districts: ["Fontaine les dijons", "Gresilles", "Mansart"]
            },
            {
                id: 13,
                name: "PE FONTAINE D'OUCHE",
                siret: "",
                isFranceService: true,
                address: {
                    building: "",
                    type: "Rue ",
                    number: "12",
                    name: "de la fontaine",
                    bp: "",
                    postalcode: "21000",
                    city: "Dijon",
                    department: 21,
                    region: "Bourgogne Franche-Comté"
                },
                places: ["Agglomération", "Asniere les dijon", "Bourroches", "Centre ville", "Chenove", "Cote d'or", "Daix", "Dijon", "Fontaine d'ouche"],
                districts: ["Fontaine les dijons", "Gresilles", "Mansart"]
            },
            {
                id: 14,
                name: "PE GRESILLES",
                siret: "",
                isFranceService: true,
                address: {
                    building: "",
                    type: "Rue ",
                    number: "12",
                    name: "de gressiles",
                    bp: "",
                    postalcode: "21000",
                    city: "Dijon",
                    department: 21,
                    region: "Bourgogne Franche-Comté",
                },
                places: ["Agglomération", "Asniere les dijon", "Bourroches", "Centre ville", "Chenove", "Cote d'or", "Daix", "Dijon", "Fontaine d'ouche"],
                districts: ["Fontaine les dijons", "Gresilles", "Mansart"]
            },
            {
                id: 15,
                name: "PE Maison des seniors",
                siret: "",
                isFranceService: true,
                address: {
                    building: "",
                    type: "Rue ",
                    number: "12",
                    name: "des seniors",
                    bp: "",
                    postalcode: "21000",
                    city: "Dijon",
                    department: 21,
                    region: "Bourgogne Franche-Comté",
                },
                places: ["Agglomération", "Asniere les dijon", "Bourroches", "Centre ville", "Chenove", "Cote d'or", "Daix", "Dijon", "Fontaine d'ouche"],
                districts: ["Fontaine les dijons", "Gresilles", "Mansart"]
            },
            {
                id: 16,
                name: "PE Mansart",
                siret: "",
                isFranceService: true,
                address: {
                    building: "",
                    type: "Rue ",
                    number: "12",
                    name: "Mansart",
                    bp: "",
                    postalcode: "21000",
                    city: "Dijon",
                    department: 21,
                    region: "Bourgogne Franche-Comté",
                },
                places: ["Agglomération", "Asniere les dijon", "Bourroches", "Centre ville", "Chenove", "Cote d'or", "Daix", "Dijon", "Fontaine d'ouche"],
                districts: ["Fontaine les dijons", "Gresilles", "Mansart"]
            },
            {
                id: 17,
                name: "PE Toison d'or",
                siret: "",
                isFranceService: true,
                address: {
                    building: "",
                    type: "Rue ",
                    number: "12",
                    name: "des Bourroches",
                    bp: "",
                    postalcode: "21000",
                    city: "Dijon",
                    department: 21,
                    region: "Bourgogne Franche-Comté",
                },
                places: ["Agglomération", "Asniere les dijon", "Bourroches", "Centre ville", "Chenove", "Cote d'or", "Daix", "Dijon", "Fontaine d'ouche"],
                districts: ["Fontaine les dijons", "Gresilles", "Mansart"],
                children: [
                    {
                        id: 20,
                        name: "Permanence 1",
                        siret: "",
                        isFranceService: true,
                        address: {
                            building: "",
                            type: "Rue ",
                            number: "12",
                            name: "des permanences",
                            bp: "",
                            postalcode: "21000",
                            city: "Dijon",
                            department: 21,
                            region: "Bourgogne Franche-Comté",
                        },
                        places: ["Agglomération", "Asniere les dijon", "Bourroches", "Centre ville", "Chenove", "Cote d'or", "Daix", "Dijon", "Fontaine d'ouche"],
                        districts: ["Fontaine les dijons", "Gresilles", "Mansart"]
                    },
                    {
                        id: 21,
                        name: "Permanence 2",
                        siret: "",
                        isFranceService: true,
                        address: {
                            building: "",
                            type: "Rue ",
                            number: "13",
                            name: "des Bourroches",
                            bp: "",
                            postalcode: "21000",
                            city: "Dijon",
                            department: 21,
                            region: "Bourgogne Franche-Comté",
                        },
                        places: ["Agglomération", "Asniere les dijon", "Bourroches", "Centre ville", "Chenove", "Cote d'or", "Daix", "Dijon", "Fontaine d'ouche"],
                        districts: ["Fontaine les dijons", "Gresilles", "Mansart"]
                    }
                ]
            },
            {
                id: 18,
                name: "Point d'accueil principal",
                siret: "",
                isFranceService: true,
                address: {
                    building: "",
                    type: "Rue ",
                    number: "12",
                    name: "de Dijon",
                    bp: "",
                    postalcode: "21000",
                    city: "Dijon",
                    department: 21,
                    region: "Bourgogne Franche-Comté",
                },
                places: ["Agglomération", "Asniere les dijon", "Bourroches", "Centre ville", "Chenove", "Cote d'or", "Daix", "Dijon", "Fontaine d'ouche"],
                districts: ["Fontaine les dijons", "Gresilles", "Mansart"]
            }
        ]
    }
]

export const office = {
    id: 11,
    name: "PE BOURROCHES",
    siret: "",
    isFranceService: true,
    address: {
        building: "",
        type: "Rue",
        number: "12",
        name: "des Bourroches",
        bp: "",
        postalcode: "21000",
        city: "Dijon",
        department: 21,
        region: "Bourgogne Franche-Comté"
    },
    places: ["Agglomération", "Asniere les dijon", "Bourroches", "Centre ville", "Chenove", "Cote d'or", "Daix", "Dijon", "Fontaine d'ouche"],
    districts: ["Fontaine les dijons", "Gresilles", "Mansart"],
    parent: 1,
    actors: [
        actors[0],
        actors[1],
        actors[5],
        actors[8],
        actors[11],
        actors[12],
    ],
    services: [
        'Enveloppes',
        'Scan et/ou envoi par mail du scan',
        'Scan négatif diapo',
        'Solidarité transport'],
    orientations: [
        'Par le site Internet / page Facebook',
        'Par les réseaux sociaux',
        'Par les opérateurs de services publics',
        'Par des structures associatives locales',
        'Par centre sociaux/espaces de quartier',],
    planning: ['8h-9h', '9h-10h', '10h-11h', '11h-12h']
}
