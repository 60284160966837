import React from 'react';
import theme from "../../utils/ui/CustomTheme";
import {connect} from 'react-redux';
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import routes from "../routes";
import PrivateRoute from "./route/PrivateRoute"
import {ConnectedRouter} from 'connected-react-router'
import history from "../../utils/history";
import {Route, Switch} from "react-router-dom";
import {authActions} from "../../modules/authentication/auth.actions";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Layout.css';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {clearCacheAtLogout} from "../../utils/utils";

toast.configure({
    position: "top-center"
});

function Layout({dispatch, ...props}) {
    const logout = () => {
        clearCacheAtLogout();
        dispatch(authActions.logout)
    }

    //TODO temp patch
    if (localStorage.getItem("user")) {
        let user = JSON.parse(localStorage.getItem('user'));
        if (!user.roles) {
            logout()
        }
    }

    return (
      <MuiThemeProvider theme={theme}>
          <ConnectedRouter history={history}>
              <div className="App">
                  <div className="App-wrapper">
                      {props.loggedIn && <Header user={props.currentUser} logout={logout}/>}
                      <Switch>
                          {routes.map(route => (
                            route.protected === false ? <Route key={route.path} {...route} /> :
                              <PrivateRoute loggedIn={props.loggedIn}
                                            role={props.currentUser ? props.currentUser.roles : ""}
                                            key={route.path} {...route} />
                          ))}
                      </Switch>
                  </div>
                  <Footer/>
              </div>
          </ConnectedRouter>
      </MuiThemeProvider>
    );
}

const mapStateToProps = function (state) {
    return {
        loggedIn: state.authentication.loggedIn,
        currentUser: state.authentication.user
    }
}

export default connect(mapStateToProps)(Layout);
