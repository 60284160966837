import {Role} from "../../utils/roles";
import {toast} from 'react-toastify';

const APIURL = process.env.REACT_APP_APIURL
const mockup = process.env.REACT_APP_MOCKUP
export const authService = {
    login,
    logout,
    askResetPassword,
    resetPassword,
    refreshToken
};

function refreshToken(token) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json',},
        body: JSON.stringify({refreshToken: token})
    };
    return new Promise((resolve, reject) => {
        fetch(APIURL + `/token/refresh`, requestOptions)
          .then(handleResponse)
          .then(user => {
              resolve(user);
          }).catch(reason => {
            reject(reason)
        });
    });
}

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json',},
        body: JSON.stringify({email: username, password: password})
    };
    if (mockup === "true") {
        return new Promise(resolve => {
            let user = {
                username: "username",
                officeId: 11,
                roles: [(username === "national" ? Role.AdminNational : (username === "local" ? Role.AdminLocal : Role.Mediateur))],
                token: "devtoken"
            }
            localStorage.setItem('user', JSON.stringify(user));
            resolve(user);
        })
    }
    return new Promise((resolve, reject) => {
        fetch(APIURL + `/login_check`, requestOptions)
          .then(handleResponse)
          .then(user => {
              resolve(user);
          }).catch(reason => {
            reject(reason)
        });
    });

}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.reload(true)
}

function askResetPassword(username) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json',},
        body: JSON.stringify({email: username})
    };

    if (mockup === "true") {
        return new Promise(resolve => {
            //OK //TODO. toast
            resolve();
        })

    }
    return fetch(APIURL + `/users/ask-reset-password`, requestOptions)
      .then(handleResponse)
      .then(resp => {
          //TODO. toast
          return "ok";
      });
}

function resetPassword(token, newPassword) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json',},
        body: JSON.stringify({resetToken: token, password: newPassword})
    };

    if (mockup === "true") {
        return new Promise(resolve => {
            //OK //TODO. toast
            resolve();
        })

    }
    return fetch(APIURL + `/users/reset-password`, requestOptions)
      .then(handleResponse)
      .then(resp => {
          //TODO. toast
          return "ok";
      });

}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                // window.location.reload(true);
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
