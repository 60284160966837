import {mediationsConstants} from './mediations.constants';

const initialState = {mediations: null, mediation: null, loading: false, error: null, created:false}

export function mediations(state = initialState, action) {
    switch (action.type) {
        case mediationsConstants.MEDIATIONS_FETCH_REQUEST:
            return {
                mediations: state.mediations,
                loading: true,
                error: null
            };
        case mediationsConstants.MEDIATIONS_FETCH_SUCCESS:
            return {
                mediations: action.mediations,
                loading: false,
                error: null
            };
        case mediationsConstants.MEDIATIONS_FETCH_FAILURE:
            return {
                mediations: state.mediations,
                loading: false,
                error: action.error
            };
        case mediationsConstants.MEDIATIONS_FETCH_ONE_REQUEST:
            return {
                mediation: state.mediation,
                loading: true,
                error: null
            };
        case mediationsConstants.MEDIATIONS_FETCH_ONE_SUCCESS:
            return {
                mediation: action.mediation,
                loading: false,
                error: null
            };
        case mediationsConstants.MEDIATIONS_FETCH_ONE_FAILURE:
            return {
                mediation: state.mediation,
                loading: false,
                error: action.error
            };
        case mediationsConstants.MEDIATIONS_DELETE_REQUEST:
            return {
                mediations: state.mediations,
                loading: true,
                error: null
            };
        case mediationsConstants.MEDIATIONS_DELETE_SUCCESS:
            return {
                loading: false,
                error: null,
                mediations: state.mediations
            };
        case mediationsConstants.MEDIATIONS_DELETE_FAILURE:
            return {
                mediations: state.mediations,
                loading: false,
                error: action.error,
            };
        case mediationsConstants.MEDIATIONS_CREATE_REQUEST:
        case mediationsConstants.OUTMEDIATIONS_CREATE_REQUEST:
        case mediationsConstants.WORKSHOP_CREATE_REQUEST:
        case mediationsConstants.REPRESENTATIONS_CREATE_REQUEST:
            return {
                loading:true
            };
        case mediationsConstants.MEDIATIONS_CREATE_SUCCESS:
        case mediationsConstants.OUTMEDIATIONS_CREATE_SUCCESS:
        case mediationsConstants.WORKSHOP_CREATE_SUCCESS:
        case mediationsConstants.REPRESENTATIONS_CREATE_SUCCESS:
            return {
                created:true,
                loading:false
            };
        case mediationsConstants.MEDIATIONS_CREATE_FAILURE:
        case mediationsConstants.OUTMEDIATIONS_CREATE_FAILURE:
        case mediationsConstants.WORKSHOP_CREATE_FAILURE:
        case mediationsConstants.REPRESENTATIONS_CREATE_FAILURE:
            return {
                created:false,
                loading:false
            };
        default:
            return state
    }
}
