import {themesConstants} from './themes.constants';

const initialState = {themes: null, theme: null, loading: false, error: null}

export function themes(state = initialState, action) {
    switch (action.type) {
        case themesConstants.THEMES_FETCH_REQUEST:
            return {
                themes: state.themes,
                loading: true,
                error: null
            };
        case themesConstants.THEMES_FETCH_SUCCESS:
            return {
                themes: action.themes,
                loading: false,
                error: null
            };
        case themesConstants.THEMES_FETCH_FAILURE:
            return {
                themes: state.themes,
                loading: false,
                error: action.error
            };
            case themesConstants.THEMES_FETCH_ONE_REQUEST:
            return {
                theme: state.theme,
                loading: true,
                error: null
            };
        case themesConstants.THEMES_FETCH_ONE_SUCCESS:
            return {
                theme: action.theme,
                loading: false,
                error: null
            };
        case themesConstants.THEMES_FETCH_ONE_FAILURE:
            return {
                theme: state.theme,
                loading: false,
                error: action.error
            };
        case themesConstants.THEMES_DELETE_REQUEST:
            return {
                themes: state.themes,
                loading: true,
                error: null
            };
        case themesConstants.THEMES_DELETE_SUCCESS:
        case themesConstants.THEMES_CREATE_SUCCESS:
        case themesConstants.THEMES_UPDATE_SUCCESS:
            return {
                loading: false,
                error: null,
                themes: null
            };
        case themesConstants.THEMES_DELETE_FAILURE:
            return {
                themes: state.themes,
                loading: false,
                error: action.error,
            };
        default:
            return state
    }
}
