import {orientationsConstants} from './orientations.constants';

const initialState = {orientations: null, orientation: null, loading: false, error: null}

export function orientations(state = initialState, action) {
    switch (action.type) {
        case orientationsConstants.ORIENTATIONS_FETCH_REQUEST:
            return {
                ...state,
                orientations: state.orientations,
                loading: true,
                error: null
            };
        case orientationsConstants.ORIENTATIONS_FETCH_SUCCESS:
            return {
                ...state,
                orientations: action.orientations,
                loading: false,
                error: null
            };
        case orientationsConstants.ORIENTATIONS_FETCH_FAILURE:
            return {
                ...state,
                orientations: state.orientations,
                loading: false,
                error: action.error
            };
        case orientationsConstants.ORIENTATIONS_FETCH_ONE_REQUEST:
            return {
                ...state,
                orientation: state.orientation,
                loading: true,
                error: null
            };
        case orientationsConstants.ORIENTATIONS_FETCH_ONE_SUCCESS:
            return {
                ...state,
                orientation: action.orientation,
                loading: false,
                error: null
            };
        case orientationsConstants.ORIENTATIONS_FETCH_ONE_FAILURE:
            return {
                ...state,
                orientation: state.orientation,
                loading: false,
                error: action.error
            };
        case orientationsConstants.ORIENTATIONS_DELETE_REQUEST:
            return {
                ...state,
                orientations: state.orientations,
                loading: true,
                error: null
            };
        case orientationsConstants.ORIENTATIONS_DELETE_SUCCESS:
        case orientationsConstants.ORIENTATIONS_CREATE_SUCCESS:
        case orientationsConstants.ORIENTATIONS_UPDATE_SUCCESS:
            return {
                loading: false,
                error: null,
                orientations: null,
            };
        case orientationsConstants.ORIENTATIONS_DELETE_FAILURE:
            return {
                orientations: state.orientations,
                loading: false,
                error: action.error,
            };
        default:
            return state
    }
}
