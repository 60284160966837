import React, {useState} from 'react';
import {
    Dialog,
    DialogActions,
    DialogTitle, FormControl,
    Grid,
    MenuItem,
    Paper,
    Select,
    Typography,
    useMediaQuery
} from "@material-ui/core";
import './InfoBar.css';
import {getCurrentDate, getCurrentTime} from "../../../utils/date";
import Loader from "./loading/loader";
import {getRole} from "../../../utils/roles";
import {connect, useDispatch} from "react-redux";
import {officesActions} from "../../../modules/offices/offices.actions";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import {faEdit, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Modal from "@material-ui/core/Modal";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import DialogContent from "@material-ui/core/DialogContent";
import history from "../../../utils/history";
import {helper} from "../../../modules/helper";

function InfoBar({...props}) {
    const [currentDate, setCurrentDate] = useState(getCurrentDate)
    const [currentTime, setCurrentTime] = useState(getCurrentTime)
    const [open, setOpen] = React.useState(false);
    const isMobile = useMediaQuery('(max-width:980px)')
    const dispatch = useDispatch();
    const {currentUser} = props;
    const [currentOffice, setCurrentOffice] = useState(currentUser.currentOffice)

    if (currentUser.hasOwnProperty("currentOffice")) {
        if (!props.userOffice && !props.loading && !props.error) {
            dispatch(officesActions.fetchUserOffice(currentUser.currentOffice));
        } else if (!props.officesAvailables && !props.loading && !props.error ) {
            dispatch(officesActions.fetchOfficesAvailables());
        }
    }

    const getUserOffices = () => {
        let offices = [];
        let child;
        let grandChild;
        let greatGrandChild;
        for (let [key, value] of Object.entries(props.officesAvailables)) {
            child = value
            offices.push({id:key, name:value.name})
            if (child.children) {
                for (let [key, value] of Object.entries(child.children)) {
                    if (key != 'name') {
                        offices.push({id:key, child:value.name})
                        grandChild = value
                        if (grandChild.children) {
                            for (let [key, value] of Object.entries(grandChild.children)) {
                                offices.push({id:key, grandChild:value.name})
                                greatGrandChild = value
                                if (currentUser.roles.includes("ROLE_ADMIN")) {
                                    for (let [key, value] of Object.entries(greatGrandChild.children)) {
                                        offices.push({id:key, greatGrandChild:value.name})
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return offices
    }

    const setNewOffice = () => {
        setOpen(false)
        dispatch(officesActions.setCurrentOffice({currentOffice: currentOffice})).then(() => {
            //New office à setter en localStorage.
            helper.setNewCurrentOffice(currentOffice);
            setTimeout(() => {
                window.location.reload()
            }, 500)
        })
    }

    const displayChangeOfficeButton = () => {
        try {
            return getUserOffices().length > 1;
            // let nationalIri = helper.getNationalOfficeIri();
            // let temp = nationalIri.split("/");
            // return currentOffice != temp[temp.length-1];
        } catch(e) {}

        return true;
    }

    setTimeout(() => {
        setCurrentTime(getCurrentTime)
    }, 1000 * 10)

    return (
      <Paper elevation={0} className="bandeau" square>
          {!isMobile &&
          <Grid container justify={"center"} alignItems={"center"}>
              <Grid container item xs={6} alignItems={"center"}>
                  <Grid item xs>
                      <Typography variant="h6"><span className="uppercase">{currentUser.lastname}</span>&nbsp;
                          <span>{currentUser.firstname}</span></Typography>
                      <Typography>
                          {getRole(currentUser.roles)} {props.userOffice && <span>{props.userOffice.name}</span>}
                          {displayChangeOfficeButton() &&
                          <Button variant="text" color="inherit" className="button-change-office" onClick={() => setOpen(true)}>
                              <FontAwesomeIcon icon={faEdit}/> Changer
                          </Button>
                          }
                      </Typography>

                  </Grid>
              </Grid>
              <Grid container item xs={2} alignItems={"flex-end"}>
                  <Grid item xs className="text-right">{currentDate} {currentTime}</Grid>
              </Grid>
          </Grid>
          }
          {isMobile &&
          <Grid container justify={"center"} alignItems={"center"} className="mt-2">
              <Grid container item xs={8} alignItems={"center"}>
                  <Grid item xs>
                      <Typography variant="h6"><span className="uppercase">{currentUser.lastname}</span>&nbsp;
                          <span>{currentUser.firstname}</span></Typography>
                      <Typography>
                          {getRole(currentUser.roles)} {props.userOffice && <span>{props.userOffice.name}</span>}
                          {displayChangeOfficeButton() &&
                          <Button variant="text" color="inherit" className="button-change-office" onClick={() => setOpen(true)}>
                              <FontAwesomeIcon icon={faEdit}/> Changer
                          </Button>
                          }
                      </Typography>
                  </Grid>
              </Grid>
              <Grid container item xs={4} alignItems={"flex-end"}>
                  <Grid item xs>{currentDate} {currentTime}</Grid>
              </Grid>
          </Grid>
          }

          <Dialog onClose={() =>setOpen(false)} aria-labelledby="dialogConfirm" open={open}
                  maxWidth="sm" fullWidth={true}>
              <DialogTitle id="dialogConfirm-title">
                  <Typography variant="h5">Changer de structure</Typography>
              </DialogTitle>
              <DialogContent className="dialog-change-office">
                  <Typography>Choisissez le nouveau site auquel vous rattacher</Typography>
                          {props.officesAvailables &&
                          <Select
                            className="select-dense"
                            variant="outlined"
                            fullWidth
                            value={currentOffice}
                            label="Site de rattachement"
                            onChange={(event) => {
                                setCurrentOffice(event.target.value)
                            }}
                            inputProps={{
                                name: 'offices',
                                id: 'offices',
                            }}>
                              {getUserOffices().map((o) => {

                                if (currentUser.roles.includes("ROLE_ADMIN")) {
                                    if (o.child) {
                                        return (
                                            <MenuItem key={o.id} value={o.id} style={{ fontWeight: 800, backgroundColor: '#EBEBEB', paddingLeft: '20px'}}>
                                                {o.child}
                                            </MenuItem>
                                          )
                                      } else if (o.grandChild) {
                                          return(
                                            <MenuItem key={o.id} value={o.id} style={{ marginLeft: '40px'}}>
                                                {o.grandChild}
                                            </MenuItem>
                                      )
                                      } else if (o.greatGrandChild) {
                                        return(
                                          <MenuItem key={o.id} value={o.id} style={{ marginLeft: '60px'}}>
                                              {o.greatGrandChild}
                                          </MenuItem>
                                      )
                                      } else {
                                        return (
                                            <MenuItem key={o.id} value={o.id} style={{ fontWeight: 800, backgroundColor: '#EBEBEB' }}>
                                                {o.name}
                                            </MenuItem>
                                        )
                                    }
                                } else {
                                    if (o.child) {
                                        return (
                                            <MenuItem key={o.id} value={o.id} style={{ marginLeft: '20px'}}>
                                                {o.child}
                                            </MenuItem>
                                        )
                                    } else if (o.grandChild) {
                                        return(
                                            <MenuItem key={o.id} value={o.id} style={{ marginLeft: '40px'}}>
                                                {o.grandChild}
                                            </MenuItem>
                                    )

                                    } else {
                                        return (
                                            <MenuItem key={o.id} value={o.id} style={{ fontWeight: 800, backgroundColor: '#EBEBEB' }}>
                                                {o.name}
                                            </MenuItem>
                                        )
                                    }
                                }

                              })}
                          </Select>
                          }

              </DialogContent>
              <DialogActions>
                  <Button variant={"contained"} color={"primary"} onClick={() => setNewOffice()}>
                      Valider
                  </Button>
              </DialogActions>
          </Dialog>
      </Paper>
    );
}

const mapStateToProps = function (state) {
    return {
        userOffice: state.offices.userOffice,
        error: state.offices.error,
        loading: state.offices.loading,
        officesAvailables: state.offices.officesAvailables
    }
}

export default connect(mapStateToProps)(InfoBar);
