export const mediationsConstants = {
    MEDIATIONS_FETCH_REQUEST: "MEDIATIONS_FETCH_REQUEST",
    MEDIATIONS_FETCH_SUCCESS: "MEDIATIONS_FETCH_SUCCESS",
    MEDIATIONS_FETCH_FAILURE: "MEDIATIONS_FETCH_FAILURE",

    MEDIATIONS_FETCH_ONE_REQUEST: "MEDIATIONS_FETCH_ONE_REQUEST",
    MEDIATIONS_FETCH_ONE_SUCCESS: "MEDIATIONS_FETCH_ONE_SUCCESS",
    MEDIATIONS_FETCH_ONE_FAILURE: "MEDIATIONS_FETCH_ONE_FAILURE",

    MEDIATIONS_CREATE_REQUEST: "MEDIATIONS_CREATE_REQUEST",
    MEDIATIONS_CREATE_SUCCESS: "MEDIATIONS_CREATE_SUCCESS",
    MEDIATIONS_CREATE_FAILURE: "MEDIATIONS_CREATE_FAILURE",

    OUTMEDIATIONS_CREATE_REQUEST: "OUTMEDIATIONS_CREATE_REQUEST",
    OUTMEDIATIONS_CREATE_SUCCESS: "OUTMEDIATIONS_CREATE_SUCCESS",
    OUTMEDIATIONS_CREATE_FAILURE: "OUTMEDIATIONS_CREATE_FAILURE",

    WORKSHOP_CREATE_REQUEST: "WORKSHOP_CREATE_REQUEST",
    WORKSHOP_CREATE_SUCCESS: "WORKSHOP_CREATE_SUCCESS",
    WORKSHOP_CREATE_FAILURE: "WORKSHOP_CREATE_FAILURE",

    REPRESENTATIONS_CREATE_REQUEST: "REPRESENTATIONS_CREATE_REQUEST",
    REPRESENTATIONS_CREATE_SUCCESS: "REPRESENTATIONS_CREATE_SUCCESS",
    REPRESENTATIONS_CREATE_FAILURE: "REPRESENTATIONS_CREATE_FAILURE",

    MEDIATIONS_UPDATE_REQUEST: "MEDIATIONS_UPDATE_REQUEST",
    MEDIATIONS_UPDATE_SUCCESS: "MEDIATIONS_UPDATE_SUCCESS",
    MEDIATIONS_UPDATE_FAILURE: "MEDIATIONS_UPDATE_FAILURE",

    MEDIATIONS_DELETE_REQUEST: "MEDIATIONS_DELETE_REQUEST",
    MEDIATIONS_DELETE_SUCCESS: "MEDIATIONS_DELETE_SUCCESS",
    MEDIATIONS_DELETE_FAILURE: "MEDIATIONS_DELETE_FAILURE",

    MEDIATIONS_WRITE_CACHE: "MEDIATIONS_WRITE_CACHE"


};
