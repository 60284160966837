import React, {lazy, Suspense} from 'react';
import Loader from "../app/components/layout/loading/loader";

const loadable = (
  importFunc,
  { fallback = <Loader /> } = { fallback: React.node },
) => {
    const LazyComponent = lazy(importFunc);

    return props => (
      <Suspense fallback={fallback}>
          <LazyComponent {...props} />
      </Suspense>
    );
};

export default loadable;
