import {reportsConstants} from './reports.constants';

const initialState = {report: null, loading: false, error: null, generating: false}

export function reports(state = initialState, action) {
    switch (action.type) {
        case reportsConstants.REPORT_FETCH_REQUEST:
            return {
                report: state.report,
                loading: true,
                error: null,
                generating: false
            };
        case reportsConstants.REPORT_FETCH_SUCCESS:
            return {
                report: action.report,
                loading: false,
                error: null,
                generating: false
            };
        case reportsConstants.REPORT_FETCH_FAILURE:
            return {
                report: state.report,
                loading: false,
                error: action.error,
                generating: false
            };
        case reportsConstants.REPORT_GENERATE_REQUEST:
            return {
                ...state,
                generating: true,
            }
        case reportsConstants.REPORT_GENERATE_SUCCESS:
            return {
                ...state,
                generating: false,
            }
        case reportsConstants.REPORT_GENERATE_FAILURE:
            return {
                ...state,
                generating: false,
            }
        default:
            return state
    }
}
