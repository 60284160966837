export const Role = {
    AdminNational: 'ROLE_ADMIN',
    AdminLocal: 'ROLE_RESPONSABLE',
    Mediateur: 'ROLE_MEDIATEUR'
}

export const getRole = (roles) => {
    if (roles && Array.isArray(roles)) {
        if (roles.includes(Role.AdminNational)) {
            return "Administrateur";
        }
        if (roles.includes(Role.AdminLocal)) {
            return "Responsable";
        }
        if (roles.includes(Role.Mediateur)) {
            return "Médiateur";
        }

    }
    return "";
}

export const isAdmin = () => {
    let user = JSON.parse(localStorage.getItem("user"))
    if (user) {
        if (user.hasOwnProperty("roles")) {
            if (user.roles.includes(Role.AdminNational)) {
                return true;
            }
        }
    }

    return false;
}

/**
 *
 * @param andNotAdmin : is responsable and only responsable, admin isn't considered responsable
 * @returns {boolean}
 */
export const isResponsable = (andNotAdmin = false) => {

    let user = JSON.parse(localStorage.getItem("user"))
    if (user) {
        if (user.hasOwnProperty("roles")) {
            if (andNotAdmin) {
                if (user.roles.includes(Role.AdminLocal) && !user.roles.includes(Role.AdminNational)) {
                    return true;
                }
            } else {
                if (user.roles.includes(Role.AdminLocal)) {
                    return true;
                }
            }

        }
    }

    return false;
}

