export const themes = [
    {
        id: 1,
        name: "Administration"
    },
    {
        id: 2,
        name: "Banque Postale"
    },
    {
        id: 3,
        name: "Courrier"
    },
    {
        id: 4,
        name: "Emploi / Formation"
    },
    {
        id: 5,
        name: "Energie / Eau"
    },
    {
        id: 6,
        name: "Finances / Impots"
    },
    {
        id: 7,
        name: "Logement / Hébergement"
    },
    {
        id: 8,
        name: "Numérique"
    },
    {
        id: 9,
        name: "Santé"
    },
    {
        id: 10,
        name: "Social"
    },
    {
        id: 11,
        name: "Téléphonie"
    },
    {
        id: 12,
        name: "Transport"
    }
]
