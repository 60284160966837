/**
 *
 * Routes
 *
 * Array of all app routes to feed into reat-router Route
 */
import loadable from '../utils/loadable';
import {Role} from "../utils/roles";

export default [
    {
        exact: true,
        protected: true,
        path: "/(home|)",
        roles: [],
        component: loadable(() => import('./components/HomePage')),
    },
    {
        exact: true,
        protected: false,
        path: "/login",
        roles: [],
        component: loadable(() => import('./components/LoginPage')),
    },
    {
        exact: true,
        protected: false,
        path: "/forgotPassword",
        roles: [],
        component: loadable(() => import('./components/login/ForgotPasswordPage')),
    },
    {
        exact: true,
        protected: false,
        path: "/resetPassword/:token",
        roles: [],
        component: loadable(() => import('./components/login/ResetPasswordPage')),
    },
    {
        exact: true,
        path: "/demarche_rapide",
        tiles: true,
        tilesLabel: "Démarches rapides",
        roles: [Role.Mediateur, Role.AdminLocal],
        component: loadable(() => import('./components/FastMediationPage')),
    },
    {
        exact: true,
        path: "/officeMediation",
        tiles: true,
        tilesLabel: "Médiation postée",
        roles: [Role.Mediateur, Role.AdminLocal],
        component: loadable(() => import('./components/OfficeMediationPage')),
    },
    {
        exact: true,
        path: "/outMediation",
        tiles: true,
        tilesLabel: "Médiation sortante",
        roles: [Role.Mediateur, Role.AdminLocal],
        component: loadable(() => import('./components/OutMediationPage')),
    },
    {
        exact: true,
        path: "/workshop",
        tiles: true,
        tilesLabel: "Ateliers",
        roles: [Role.Mediateur, Role.AdminLocal],
        component: loadable(() => import('./components/WorkshopPage')),
    },
    {
        exact: true,
        path: "/representation",
        tiles: true,
        tilesLabel: "Représentations",
        roles: [Role.Mediateur, Role.AdminLocal],
        component: loadable(() => import('./components/RepresentationPage')),
    },
    {
        exact: true,
        path: "/offices",
        tiles: true,
        tilesLabel: "Structures",
        roles: [Role.AdminNational, Role.AdminLocal],
        component: loadable(() => import('./components/OfficesPage')),
    },
    {
        exact: true,
        path: "/offices/new",
        roles: [Role.AdminNational, Role.AdminLocal],
        component: loadable(() => import('./components/OfficesNewOrEdit')),
    },
    {
        exact: true,
        path: "/offices/:id/edit",
        roles: [Role.AdminNational, Role.AdminLocal],
        component: loadable(() => import('./components/OfficesNewOrEdit')),
    },
    {
        exact: true,
        path: "/users",
        tiles: true,
        tilesLabel: "Utilisateurs",
        roles: [Role.AdminNational, Role.AdminLocal],
        component: loadable(() => import('./components/UsersPage')),
    },
    {
        exact: true,
        path: "/users/new",
        roles: [Role.AdminNational, Role.AdminLocal],
        component: loadable(() => import('./components/UsersNewOrEdit')),
    },
    {
        exact: true,
        path: "/users/:id/edit",
        roles: [Role.AdminNational, Role.AdminLocal],
        component: loadable(() => import('./components/UsersNewOrEdit')),
    },
    {
        exact: true,
        path: "/actors",
        tiles: true,
        tilesLabel: "Opérateurs",
        roles: [Role.AdminNational, Role.AdminLocal],
        component: loadable(() => import('./components/dictionary/ActorsPage')),
    },
    {
        exact: true,
        path: "/actors/new",
        roles: [Role.AdminNational, Role.AdminLocal],
        component: loadable(() => import('./components/dictionary/ActorsNewOrEdit')),
    },
    {
        exact: true,
        path: "/actors/:id/edit",
        roles: [Role.AdminNational, Role.AdminLocal],
        component: loadable(() => import('./components/dictionary/ActorsNewOrEdit')),
    },
    {
        exact: true,
        path: "/services",
        roles: [Role.AdminNational, Role.AdminLocal],
        component: loadable(() => import('./components/dictionary/ServicesPage')),
    },
    {
        exact: true,
        path: "/services/new",
        roles: [Role.AdminNational, Role.AdminLocal],
        component: loadable(() => import('./components/dictionary/ServicesNewOrEdit')),
    },
    {
        exact: true,
        path: "/services/:id/edit",
        roles: [Role.AdminNational, Role.AdminLocal],
        component: loadable(() => import('./components/dictionary/ServicesNewOrEdit')),
    },
    {
        exact: true,
        path: "/orientations",
        roles: [Role.AdminNational, Role.AdminLocal],
        component: loadable(() => import('./components/dictionary/OrientationsPage')),
    },
    {
        exact: true,
        path: "/orientations/new",
        roles: [Role.AdminNational, Role.AdminLocal],
        component: loadable(() => import('./components/dictionary/OrientationsNewOrEdit')),
    },
    {
        exact: true,
        path: "/orientations/:id/edit",
        roles: [Role.AdminNational, Role.AdminLocal],
        component: loadable(() => import('./components/dictionary/OrientationsNewOrEdit')),
    },
    {
        exact: true,
        path: "/themes",
        tiles: true,
        tilesLabel: "Thématiques",
        roles: [Role.AdminNational, Role.AdminLocal],
        component: loadable(() => import('./components/dictionary/ThemesPage')),
    },
    {
        exact: true,
        path: "/themes/new",
        roles: [Role.AdminNational, Role.AdminLocal],
        component: loadable(() => import('./components/dictionary/ThemesNewOrEdit')),
    },
    {
        exact: true,
        path: "/themes/:id/edit",
        roles: [Role.AdminNational, Role.AdminLocal],
        component: loadable(() => import('./components/dictionary/ThemesNewOrEdit')),
    },
    {
        exact: true,
        path: "/report",
        roles: [Role.AdminNational, Role.AdminLocal, Role.Mediateur],
        component: loadable(() => import('./components/ReportFilterPage')),
    },
    {
        exact: true,
        path: "/actions",
        tiles: true,
        tilesLabel: "Actions",
        roles: [Role.AdminNational, Role.AdminLocal],
        component: loadable(() => import('./components/dictionary/ActionsPage')),
    },
    {
        exact: true,
        path: "/actions/new",
        roles: [Role.AdminNational, Role.AdminLocal],
        component: loadable(() => import('./components/dictionary/ActionsNewOrEdit')),
    },
    {
        exact: true,
        path: "/actions/:id/edit",
        roles: [Role.AdminNational, Role.AdminLocal],
        component: loadable(() => import('./components/dictionary/ActionsNewOrEdit')),
    },
    {protected: false, path: '', component: loadable(() => import('./components/layout/NotFound'))},
];
