import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSnowflake} from '@fortawesome/free-solid-svg-icons'
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import "./Loader.css";

export default function Loader({label = 'Chargement en cours ...',}) {
    return (
          <Container className="loader-container">
              <Grid container justify="center"
                    direction="column"
                    alignItems="center">
                  <Grid item>
                      <FontAwesomeIcon icon={faSnowflake} spin={true} size={"5x"}/>
                  </Grid>
                  <Grid item>
                      <h1>{label}</h1>
                  </Grid>
              </Grid>
          </Container>
    );
}
