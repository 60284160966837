import {servicesConstants} from './services.constants';

const initialState = {services: null, service: null, loading: false, error: null}

export function services(state = initialState, action) {
    switch (action.type) {
        case servicesConstants.SERVICES_FETCH_REQUEST:
            return {
                ...state,
                services: state.services,
                loading: true,
                error: null
            };
        case servicesConstants.SERVICES_FETCH_SUCCESS:
            return {
                ...state,
                services: action.services,
                loading: false,
                error: null
            };
        case servicesConstants.SERVICES_FETCH_FAILURE:
            return {
                ...state,
                services: state.services,
                loading: false,
                error: action.error
            };
        case servicesConstants.SERVICES_FETCH_ONE_REQUEST:
            return {
                ...state,
                service: state.service,
                loading: true,
                error: null
            };
        case servicesConstants.SERVICES_FETCH_ONE_SUCCESS:
            return {
                ...state,
                service: action.service,
                loading: false,
                error: null
            };
        case servicesConstants.SERVICES_FETCH_ONE_FAILURE:
            return {
                ...state,
                service: state.service,
                loading: false,
                error: action.error
            };
        case servicesConstants.SERVICES_DELETE_REQUEST:
            return {
                services: state.services,
                loading: true,
                error: null
            };
        case servicesConstants.SERVICES_DELETE_SUCCESS:
        case servicesConstants.SERVICES_CREATE_SUCCESS:
        case servicesConstants.SERVICES_UPDATE_SUCCESS:
            return {
                loading: false,
                error: null,
                services: null
            };
        case servicesConstants.SERVICES_DELETE_FAILURE:
            return {
                services: state.services,
                loading: false,
                error: action.error,
            };
        default:
            return state
    }
}
