export const reportsConstants = {
    REPORT_FETCH_REQUEST: "REPORT_FETCH_REQUEST",
    REPORT_FETCH_SUCCESS: "REPORT_FETCH_SUCCESS",
    REPORT_FETCH_FAILURE: "REPORT_FETCH_FAILURE",


    REPORT_GENERATE_REQUEST: "REPORT_GENERATE_REQUEST",
    REPORT_GENERATE_SUCCESS: "REPORT_GENERATE_SUCCESS",
    REPORT_GENERATE_FAILURE: "REPORT_GENERATE_FAILURE",

};
