const days = ["Dimanche","Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

export const getCurrentDate = () => {
    let today = new Date();
    let currentDay = today.getDate();
    let currentMonth = today.getMonth();
    let currentYear = today.getFullYear();

    return days[today.getDay()] + " " + currentDay + " " + months[currentMonth] + " " + currentYear
}

export const getCurrentTime = () => {
    let today = new Date();
    let currentHour = today.getHours();
    if (currentHour < 10) currentHour = "0" + currentHour;
    let currentMinutes = today.getMinutes();
    if (currentMinutes < 10) currentMinutes = "0" + currentMinutes;

    return currentHour + ":" + currentMinutes;
}
