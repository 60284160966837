export const officesConstants = {
    OFFICES_FETCH_REQUEST: "OFFICES_FETCH_REQUEST",
    OFFICES_FETCH_SUCCESS: "OFFICES_FETCH_SUCCESS",
    OFFICES_FETCH_FAILURE: "OFFICES_FETCH_FAILURE",

    OFFICESAVAILABLE_FETCH_REQUEST: "OFFICESAVAILABLE_FETCH_REQUEST",
    OFFICESAVAILABLE_FETCH_SUCCESS: "OFFICESAVAILABLE_FETCH_SUCCESS",
    OFFICESAVAILABLE_FETCH_FAILURE: "OFFICESAVAILABLE_FETCH_FAILURE",

    OFFICES_FETCH_ONE_REQUEST: "OFFICES_FETCH_ONE_REQUEST",
    OFFICES_FETCH_ONE_SUCCESS: "OFFICES_FETCH_ONE_SUCCESS",
    OFFICES_FETCH_ONE_FAILURE: "OFFICES_FETCH_ONE_FAILURE",

    OFFICES_FETCH_USER_REQUEST: "OFFICES_FETCH_USER_REQUEST",
    OFFICES_FETCH_USER_SUCCESS: "OFFICES_FETCH_USER_SUCCESS",
    OFFICES_FETCH_USER_FAILURE: "OFFICES_FETCH_USER_FAILURE",

    OFFICES_CREATE_REQUEST: "OFFICES_CREATE_REQUEST",
    OFFICES_CREATE_SUCCESS: "OFFICES_CREATE_SUCCESS",
    OFFICES_CREATE_FAILURE: "OFFICES_CREATE_FAILURE",

    OFFICES_UPDATE_REQUEST: "OFFICES_UPDATE_REQUEST",
    OFFICES_UPDATE_SUCCESS: "OFFICES_UPDATE_SUCCESS",
    OFFICES_UPDATE_FAILURE: "OFFICES_UPDATE_FAILURE",

    OFFICES_DELETE_REQUEST: "OFFICES_DELETE_REQUEST",
    OFFICES_DELETE_SUCCESS: "OFFICES_DELETE_SUCCESS",
    OFFICES_DELETE_FAILURE: "OFFICES_DELETE_FAILURE",

    OFFICES_SET_CURRENT_REQUEST: "OFFICES_SET_CURRENT_REQUEST",
    OFFICES_SET_CURRENT_SUCCESS: "OFFICES_SET_CURRENT_SUCCESS",
    OFFICES_SET_CURRENT_FAILURE: "OFFICES_SET_CURRENT_FAILURE",


};
