import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import {authentication} from './authentication/auth.reducer';
import {offices} from './offices/offices.reducer'
import {users} from './users/users.reducer'
import {themes} from "./dictionary/themes/themes.reducer";
import {actors} from "./dictionary/actors/actors.reducer";
import {services} from "./dictionary/services/services.reducer";
import {orientations} from "./dictionary/orientations/orientations.reducer";
import {mediations} from "./mediations/mediations.reducer";
import {reports} from "./reports/reports.reducer";

const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    authentication,
    offices,
    users,
    themes,
    actors,
    services,
    orientations,
    mediations,
    reports
});

export default rootReducer
