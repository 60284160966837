import {office, offices} from "./offices.mockup";
import {authHeader, deleteHeaders, fetchHeaders, patchHeaders, postHeaders} from "../../utils/headers";

const APIURL = process.env.REACT_APP_APIURL
const mockup = process.env.REACT_APP_MOCKUP

export const officesService = {
    fetchOffices,
    fetchNationalOfficeIri,
    deleteOffice,
    createOffice,
    fetchOfficeById,
    setCurrentOffice,
    updateOffice,
    fetchOfficesAvailables
};

function fetchOffices(pagination) {
    const requestOptions = fetchHeaders()

    if (mockup === "true") {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(offices)
            }, 2000)

        })
    }

    return fetch(APIURL + `/offices?pagination=`+pagination, requestOptions)
      .then(handleResponse)
      .then(offices => {
          if (offices.hasOwnProperty("hydra:member")) {
              return offices["hydra:member"]
          }
          return offices
      });
}

function fetchOfficeById(id) {
    const requestOptions = fetchHeaders()

    if (mockup === "true") {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(office)
            }, 2000)

        })
    }
    return fetch(APIURL + `/offices/` + id, requestOptions)
      .then(handleResponse)
      .then(office => {
          return office
      });
}

function fetchOfficesAvailables() {
    const requestOptions = fetchHeaders()

    if (mockup === "true") {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(office)
            }, 2000)

        })
    }
    return fetch(APIURL + `/offices-availables`, requestOptions)
      .then(handleResponse)
      .then(officesAvailables => {
          return officesAvailables
      });

}   


function fetchNationalOfficeIri() {
    const requestOptions = fetchHeaders()

    if (mockup === "true") {
        return new Promise(resolve => {
            resolve(10);
        })
    }

    return fetch(APIURL + `/office/national`, requestOptions)
      .then(handleResponse)
      .then(office => {
          return office
      });
}

function deleteOffice(id) {
    const requestOptions = deleteHeaders()

    if (mockup === "true") {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(id)
            }, 2000)

        })
    }

    return fetch(APIURL + `/offices/` + id, requestOptions)
      .then(handleResponse)
      .then(() => {
          return id
      });
}

function createOffice(values) {
    const requestOptions = postHeaders(values)
    if (mockup === "true") {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve()
            }, 2000)

        })
    }

    return fetch(APIURL + `/offices`, requestOptions)
      .then(handleResponse)
      .then(() => {
          return "ok"
      });
}

function updateOffice(values) {
    const requestOptions = patchHeaders(values)
    if (mockup === "true") {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve()
            }, 2000)

        })
    }

    return fetch(APIURL + `/offices/` + values.id, requestOptions)
      .then(handleResponse)
      .then(() => {
          return "ok"
      });
}

function setCurrentOffice(value) {
    const requestOptions = patchHeaders(value)

    return fetch(APIURL + '/set-current-office', requestOptions)
      .then(handleResponse)
      .then(() => {
          return "ok"
      })
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401 || response.status === 403) {
                return Promise.reject("Erreur")
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
