import history from '../../utils/history';
import {officesConstants} from "./offices.constants";
import {officesService} from "./offices.service";
import {helper} from "../helper";
import {toastHelper} from "../toast-helper";

export const officesActions = {
    fetchOffices,
    deleteOffice,
    createOffice,
    fetchOfficeById,
    fetchUserOffice,
    updateOffice,
    setCurrentOffice,
    fetchOfficesAvailables
};

function fetchOffices(pagination = false) {
    return dispatch => {
        dispatch(request());

        officesService.fetchOffices(pagination)
          .then(
            offices => {
                dispatch(success(offices));
            },
            error => {
                dispatch(failure(error.toString()));
            }
          );
    };

    function request() {
        return {type: officesConstants.OFFICES_FETCH_REQUEST}
    }

    function success(offices) {
        return {type: officesConstants.OFFICES_FETCH_SUCCESS, offices}
    }

    function failure(error) {
        return {type: officesConstants.OFFICES_FETCH_FAILURE, error}
    }
}

function fetchUserOffice(id) {
    return dispatch => {
        dispatch(request());
        officesService.fetchOfficeById(id)
          .then(
            office => {
                helper.storeUserOffice(office)
                dispatch(success(office));
            },
            error => {
                dispatch(failure(error.toString()));
            }
          );
    };

    function request() {
        return {type: officesConstants.OFFICES_FETCH_USER_REQUEST}
    }

    function success(userOffice) {
        return {type: officesConstants.OFFICES_FETCH_USER_SUCCESS, userOffice}
    }

    function failure(error) {
        return {type: officesConstants.OFFICES_FETCH_USER_FAILURE, error}
    }
}

function fetchOfficeById(id) {
    return dispatch => {
        dispatch(request());
        officesService.fetchOfficeById(id)
          .then(
            office => {
                dispatch(success(office));
            },
            error => {
                dispatch(failure(error.toString()));
            }
          );
    };

    function request() {
        return {type: officesConstants.OFFICES_FETCH_ONE_REQUEST}
    }

    function success(office) {
        return {type: officesConstants.OFFICES_FETCH_ONE_SUCCESS, office}
    }

    function failure(error) {
        return {type: officesConstants.OFFICES_FETCH_ONE_FAILURE, error}
    }
}

function fetchOfficesAvailables() {
    return dispatch => {
        dispatch(request());
        officesService.fetchOfficesAvailables()
          .then(
            officesAvailables => {
                dispatch(success(officesAvailables));
            },
            error => {
                dispatch(failure(error.toString()));
            }
          );
    };

    function request() {
        return {type: officesConstants.OFFICESAVAILABLE_FETCH_REQUEST}
    }

    function success(officesAvailables) {
        return {type: officesConstants.OFFICESAVAILABLE_FETCH_SUCCESS, officesAvailables}
    }

    function failure(error) {
        return {type: officesConstants.OFFICESAVAILABLE_FETCH_FAILURE, error}
    }
}

function deleteOffice(id) {
    return dispatch => {
        dispatch(request());

        officesService.deleteOffice(id)
          .then(
            id => {
                dispatch(success(id));
                history.push('/offices');
            },
            error => {
                dispatch(failure(error.toString()));
            }
          );
    };


    function request() {
        return {type: officesConstants.OFFICES_DELETE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("La structure a bien été supprimée.")
        return {type: officesConstants.OFFICES_DELETE_SUCCESS, id}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: officesConstants.OFFICES_DELETE_FAILURE, error}
    }
}

function createOffice(values) {
    return dispatch => {
        dispatch(request());

        values = helper.prepareOffice(values)

        officesService.createOffice(values)
          .then(
            () => {
                dispatch(success());
                setTimeout(() => {
                    history.push('/offices');
                })

            },
            error => {
                dispatch(failure(error.toString()));
            }
          );
    };

    function request() {
        return {type: officesConstants.OFFICES_CREATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("La structure a bien été créée.")
        return {type: officesConstants.OFFICES_CREATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: officesConstants.OFFICES_CREATE_FAILURE, error}
    }
}

function updateOffice(values) {
    return dispatch => {
        dispatch(request());
        values = helper.prepareOffice(values)

        officesService.updateOffice(values)
          .then(
            () => {
                dispatch(success());
                history.push('/offices');
            },
            error => {
                dispatch(failure(error.toString()));
            }
          );
    };

    function request() {
        return {type: officesConstants.OFFICES_UPDATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("La structure a bien été modifiée.")
        return {type: officesConstants.OFFICES_UPDATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: officesConstants.OFFICES_UPDATE_FAILURE, error}
    }
}

function setCurrentOffice(newOfficeId) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());

            officesService.setCurrentOffice(newOfficeId)
              .then(
                () => {
                    dispatch(success());
                    resolve();
                    history.push("/")
                },
                error => {
                    dispatch(failure(error.toString()));
                }
              );
        })

    };

    function request() {
        return {type: officesConstants.OFFICES_SET_CURRENT_REQUEST}
    }

    function success(id) {
        return {type: officesConstants.OFFICES_SET_CURRENT_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: officesConstants.OFFICES_SET_CURRENT_FAILURE, error}
    }
}
