export const orientationsConstants = {
    ORIENTATIONS_FETCH_REQUEST: "ORIENTATIONS_FETCH_REQUEST",
    ORIENTATIONS_FETCH_SUCCESS: "ORIENTATIONS_FETCH_SUCCESS",
    ORIENTATIONS_FETCH_FAILURE: "ORIENTATIONS_FETCH_FAILURE",

    ORIENTATIONS_FETCH_ONE_REQUEST: "ORIENTATIONS_FETCH_ONE_REQUEST",
    ORIENTATIONS_FETCH_ONE_SUCCESS: "ORIENTATIONS_FETCH_ONE_SUCCESS",
    ORIENTATIONS_FETCH_ONE_FAILURE: "ORIENTATIONS_FETCH_ONE_FAILURE",

    ORIENTATIONS_CREATE_REQUEST: "ORIENTATIONS_CREATE_REQUEST",
    ORIENTATIONS_CREATE_SUCCESS: "ORIENTATIONS_CREATE_SUCCESS",
    ORIENTATIONS_CREATE_FAILURE: "ORIENTATIONS_CREATE_FAILURE",

    ORIENTATIONS_UPDATE_REQUEST: "ORIENTATIONS_UPDATE_REQUEST",
    ORIENTATIONS_UPDATE_SUCCESS: "ORIENTATIONS_UPDATE_SUCCESS",
    ORIENTATIONS_UPDATE_FAILURE: "ORIENTATIONS_UPDATE_FAILURE",

    ORIENTATIONS_DELETE_REQUEST: "ORIENTATIONS_DELETE_REQUEST",
    ORIENTATIONS_DELETE_SUCCESS: "ORIENTATIONS_DELETE_SUCCESS",
    ORIENTATIONS_DELETE_FAILURE: "ORIENTATIONS_DELETE_FAILURE",


};
