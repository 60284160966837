export const actorsConstants = {
    ACTORS_FETCH_REQUEST: "ACTORS_FETCH_REQUEST",
    ACTORS_FETCH_SUCCESS: "ACTORS_FETCH_SUCCESS",
    ACTORS_FETCH_FAILURE: "ACTORS_FETCH_FAILURE",

    ACTORS_FETCH_ONE_REQUEST: "ACTORS_FETCH_ONE_REQUEST",
    ACTORS_FETCH_ONE_SUCCESS: "ACTORS_FETCH_ONE_SUCCESS",
    ACTORS_FETCH_ONE_FAILURE: "ACTORS_FETCH_ONE_FAILURE",

    ACTORS_CREATE_REQUEST: "ACTORS_CREATE_REQUEST",
    ACTORS_CREATE_SUCCESS: "ACTORS_CREATE_SUCCESS",
    ACTORS_CREATE_FAILURE: "ACTORS_CREATE_FAILURE",

    ACTORS_UPDATE_REQUEST: "ACTORS_UPDATE_REQUEST",
    ACTORS_UPDATE_SUCCESS: "ACTORS_UPDATE_SUCCESS",
    ACTORS_UPDATE_FAILURE: "ACTORS_UPDATE_FAILURE",

    ACTORS_DELETE_REQUEST: "ACTORS_DELETE_REQUEST",
    ACTORS_DELETE_SUCCESS: "ACTORS_DELETE_SUCCESS",
    ACTORS_DELETE_FAILURE: "ACTORS_DELETE_FAILURE",

    ACTORS_ACTIONS_CREATE_REQUEST: "ACTORS_ACTIONS_CREATE_REQUEST",
    ACTORS_ACTIONS_CREATE_SUCCESS: "ACTORS_ACTIONS_CREATE_SUCCESS",
    ACTORS_ACTIONS_CREATE_FAILURE: "ACTORS_ACTIONS_CREATE_FAILURE",

    ACTORS_ACTIONS_UPDATE_REQUEST: "ACTORS_ACTIONS_UPDATE_REQUEST",
    ACTORS_ACTIONS_UPDATE_SUCCESS: "ACTORS_ACTIONS_UPDATE_SUCCESS",
    ACTORS_ACTIONS_UPDATE_FAILURE: "ACTORS_ACTIONS_UPDATE_FAILURE",

    ACTORS_ACTIONS_FETCH_ONE_REQUEST: "ACTORS_ACTIONS_FETCH_ONE_REQUEST",
    ACTORS_ACTIONS_FETCH_ONE_SUCCESS: "ACTORS_ACTIONS_FETCH_ONE_SUCCESS",
    ACTORS_ACTIONS_FETCH_ONE_FAILURE: "ACTORS_ACTIONS_FETCH_ONE_FAILURE",

    ACTORS_ACTIONS_DELETE_REQUEST: "ACTORS_ACTIONS_DELETE_REQUEST",
    ACTORS_ACTIONS_DELETE_SUCCESS: "ACTORS_ACTIONS_DELETE_SUCCESS",
    ACTORS_ACTIONS_DELETE_FAILURE: "ACTORS_ACTIONS_DELETE_FAILURE",

    ACTIONS_FETCH_REQUEST: "ACTIONS_FETCH_REQUEST",
    ACTIONS_FETCH_SUCCESS: "ACTIONS_FETCH_SUCCESS",
    ACTIONS_FETCH_FAILURE: "ACTIONS_FETCH_FAILURE",


};
