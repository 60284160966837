import {usersConstants} from './users.constants';

const initialState = {users: null, user: null, loading: false, error: null}

export function users(state = initialState, action) {
    switch (action.type) {
        case usersConstants.USERS_FETCH_REQUEST:
            return {
                users: state.users,
                loading: true,
                error: null
            };
        case usersConstants.USERS_FETCH_SUCCESS:
            return {
                users: action.users,
                loading: false,
                error: null
            };
        case usersConstants.USERS_FETCH_FAILURE:
            return {
                users: state.users,
                loading: false,
                error: action.error
            };
            case usersConstants.USERS_FETCH_ONE_REQUEST:
            return {
                user: state.user,
                loading: true,
                error: null
            };
        case usersConstants.USERS_FETCH_ONE_SUCCESS:
            return {
                user: action.user,
                loading: false,
                error: null
            };
        case usersConstants.USERS_FETCH_ONE_FAILURE:
            return {
                user: state.user,
                loading: false,
                error: action.error
            };
        case usersConstants.USERS_DELETE_REQUEST:
            return {
                users: state.users,
                loading: true,
                error: null
            };
        case usersConstants.USERS_DELETE_SUCCESS:
        case usersConstants.USERS_CREATE_SUCCESS:
        case usersConstants.USERS_UPDATE_SUCCESS:
            return {
                loading: false,
                error: null,
                users: null
            };
        case usersConstants.USERS_DELETE_FAILURE:
            return {
                users: state.users,
                loading: false,
                error: action.error,
            };
        default:
            return state
    }
}
