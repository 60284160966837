import codesPostaux from 'codes-postaux'

const colors = ["#23a33e", "#1b4f9c", "#e50746", "#fcbf0b", "#941751", "#1bac82", "#595959"];
let current = 0;

export const clearCacheAtLogout = () => {
    try {
        window.localStorage.removeItem("OfficeMediationForm");
        window.localStorage.removeItem("OfficeMediationActiveStep");
        window.localStorage.removeItem("OutMediationForm");
        window.localStorage.removeItem("filters");
    } catch (e) {
        console.error("Error clearing cache : ", e);
    }
}

export const getNextColor = () => {
    let nextColor = colors[current % 6];
    current++;
    return nextColor;
}

export const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const findCodeInsee = (codePostal, nomCommune) => {
    let data = codesPostaux.find(codePostal);
    if (data.length > 0) {
        let commune = data.find((c) => c.nomCommune == nomCommune);
        if (commune) {
            return commune.codePostal;
        }
    }
    return null;
}

export const transcodeFSTypes = {
    AGE: "age",
    DURATION: "duration",
    CONTACT: 'contact',
    SATISFACTION: 'satisfaction',
    FS_TYPE: "fs_type",
    FS_THEME: "fs_theme",
    FS_ACTS: "fs_acts",
    FS_PARTENAIRE: "fs_partenaire",
    FS_DIGITAL_AUTONOMY: "fs-digitalautonomy"
}

export const transcodeToFranceServicesReferentiel = (type, data) => {
    if (type === transcodeFSTypes.AGE) {
        if (data == "Entre 62 et 69 ans") {
            return "Plus de 62 ans";
        }
        if (data == "Entre 70 et 79 ans") {
            return "Plus de 62 ans";
        }
        if (data == "Plus de 80 ans") {
            return "Plus de 62 ans";
        }
    }
    if (type === transcodeFSTypes.DURATION) {
        if (data == "-5 min") {
            return "Moins de 5 min";
        }
        if (data == "5 à 15 min") {
            return "Entre 5 et 10 min";
        }
        if (data == "15 à 30 min") {
            return "Entre 10 et 20 min";
        }
        if (data == "30 à 45 min") {
            return "Entre 20 et 40 min";
        }
        if (data == "plus de 45 min") {
            return "Plus de 60 minutes";
        }
    }
    if (type === transcodeFSTypes.CONTACT) {
        if (data == "Par email") {
            return "Par e-mail";
        }
    }
    if (type === transcodeFSTypes.SATISFACTION) {
        if (data == "Partiellement : l'Usager devra revenir au PIMMS") {
            return "Partiellement : l'Usager devra revenir en France Services";
        }
    }
    if (type === transcodeFSTypes.FS_TYPE) {
        if (data == "L'usager a une demande nécessitant un accompagnement individuel") {
            return "Demande nécessitant un accompagnement individuel";
        }
        if (data == "L'usager souhaite accéder à un ordinateur en libre-service") {
            return "Accès à un ordinateur en libre-service";
        }
        if (data == "L'usager participe à un atelier ou une animation organisé(e) dans l'Espace France Services") {
            return "Participation à un atelier organisé dans la France Services"
        }
        if (data == "L'usager se présente pour un rendez-vous avec un partenaire") {
            return "Rendez-vous avec un partenaire (permanence ou autre)";
        }
    }
    if (type === transcodeFSTypes.FS_THEME) {
        if (data == "Permis de conduire / cartes grises / titres de séjour" || data == "Échange de permis de conduire") {
            return "Permis de conduire "
        }
        if (data == "Solidarité, insertion (RSA et prime d'activité)") {
            return "Solidarité, insertion (RSA, prime d'activité)"
        }
        if (data == "La demande concerne l'établissement ou la modification d'une carte grise") {
            return "Immatriculation de véhicule"
        }
        if (data == "Enfance et jeunesse") {
            return "Enfance et jeunesse : allocation de rentrée, projet jeune, centre de loisirs"
        }
        if (data == "Petite enfance") {
            return "Petite enfance - 0/3 ans : mode de garde, assistance maternelle, crèche"
        }
        if (data == "Remboursement de soins") {
            return "Remboursements de soins"
        }
        if (data == "Prestations en espèces (indemnités journalières, etc.à") {
            return "Prestations en espèces (indemnités journalières, etc.)"
        }
        if (data == "L'usager fait face à un litige ou un conflit") {
            return "L'usager fait face à un questionnement juridique"
        }
        if (data == "La demande concerne la perte d'un proche") {
            return "Décès"
        }
        if (data == "La demande concerne la déclaration d'une naissance") {
            return "Naissance (grossesse, maternité)"
        }
        if (data == "La demande concerne l'établissement ou le renouvellement de papiers d'identité") {
            return "Pré-demande de titre d'identité et de voyage"
        }
        if (data == "Actualisation") {
            return "Un accompagnement ou un suivi du demandeur d'emploi"
        }
    }
    if (type === transcodeFSTypes.FS_ACTS) {
        if (data == "Facilitation administrative hors démarches en ligne (Explication d'un courrier)"
          || data == "Facilitation administrative hors démarches en ligne (ex. explication d'un courrier, complétude de dossier, aide rédactionnelle, etc.)"
          || data == "Facilitation administrative hors démarches en ligne (Complétude de dossier)") {
            return "Facilitation administrative hors démarches en ligne";
        }
        if (data == "Mise en relation avec le partenaire (redirection avec ou sans RDV vers un lieu physique, un contact téléphonique ou par webconférence)") {
            return "Mise en relation avec le partenaire";
        }
        if (data == "Autre demande pour la MSA") {
            return "Autre";
        }
        if (data == "Aide à l'utilisation de pole-emploi.fr") {
            return "Aide à l'utilisation de l'inscription en ligne sur pole-emploi.fr";
        }
    }
    if (type === transcodeFSTypes.FS_PARTENAIRE) {
        if (data == "Les Allocations familiales") {
            return "La Caisse d'Allocations Familiales (CAF)"
        }
        if (data == "L'Assurance Retraite (CNAV ou CARSATT)") {
            return "L'Assurance Retraite (CNAV ou CARSAT)"
        }
    }
    if (type === transcodeFSTypes.FS_DIGITAL_AUTONOMY) {
        if (data == "Usager non autonome") {
            return "L’usager a été entièrement accompagné"
        }
        if (data == "Usager débutant") {
            return "L’usager a participé à la réalisation de la démarche (ex. utilisation clavier)"
        }
        if (data == "Usager avec niveau confirmé" || data == "Usager avec niveau expert") {
            return "L’usager a pu réaliser sa démarche en autonomie"
        }
    }
    //Default
    return data;
}
