import {actorsConstants} from './actors.constants';

const initialState = {actors: null, actor: null, action: null, actions:null, loading: false, error: null}

export function actors(state = initialState, action) {
    switch (action.type) {
        case actorsConstants.ACTORS_FETCH_REQUEST:
        case actorsConstants.ACTIONS_FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case actorsConstants.ACTORS_FETCH_SUCCESS:
            return {
                ...state,
                actors: action.actors,
                action: state.action,
                loading: false,
                error: null
            };
        case actorsConstants.ACTORS_FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case actorsConstants.ACTORS_FETCH_ONE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case actorsConstants.ACTORS_FETCH_ONE_SUCCESS:
            return {
                ...state,
                actor: action.actor,
                loading: false,
                error: null
            };
        case actorsConstants.ACTORS_FETCH_ONE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case actorsConstants.ACTORS_ACTIONS_FETCH_ONE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case actorsConstants.ACTORS_ACTIONS_FETCH_ONE_SUCCESS:
            return {
                ...state,
                action: action.action,
                loading: false,
                error: null
            };
        case actorsConstants.ACTORS_ACTIONS_FETCH_ONE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case actorsConstants.ACTORS_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case actorsConstants.ACTORS_DELETE_SUCCESS:
        case actorsConstants.ACTORS_CREATE_SUCCESS:
        case actorsConstants.ACTORS_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                actors: null
            };
        case actorsConstants.ACTORS_DELETE_FAILURE:
        case actorsConstants.ACTIONS_FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case actorsConstants.ACTORS_ACTIONS_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case actorsConstants.ACTORS_ACTIONS_DELETE_SUCCESS:
        case actorsConstants.ACTORS_ACTIONS_CREATE_SUCCESS:
        case actorsConstants.ACTORS_ACTIONS_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                actors: null
            };
        case actorsConstants.ACTORS_ACTIONS_DELETE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case actorsConstants.ACTIONS_FETCH_SUCCESS:
            return {
                ...state,
                loading:false,
                actions:action.actions
            }
        default:
            return state
    }
}
