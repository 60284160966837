export const servicesConstants = {
    SERVICES_FETCH_REQUEST: "SERVICES_FETCH_REQUEST",
    SERVICES_FETCH_SUCCESS: "SERVICES_FETCH_SUCCESS",
    SERVICES_FETCH_FAILURE: "SERVICES_FETCH_FAILURE",

    SERVICES_FETCH_ONE_REQUEST: "SERVICES_FETCH_ONE_REQUEST",
    SERVICES_FETCH_ONE_SUCCESS: "SERVICES_FETCH_ONE_SUCCESS",
    SERVICES_FETCH_ONE_FAILURE: "SERVICES_FETCH_ONE_FAILURE",

    SERVICES_CREATE_REQUEST: "SERVICES_CREATE_REQUEST",
    SERVICES_CREATE_SUCCESS: "SERVICES_CREATE_SUCCESS",
    SERVICES_CREATE_FAILURE: "SERVICES_CREATE_FAILURE",

    SERVICES_UPDATE_REQUEST: "SERVICES_UPDATE_REQUEST",
    SERVICES_UPDATE_SUCCESS: "SERVICES_UPDATE_SUCCESS",
    SERVICES_UPDATE_FAILURE: "SERVICES_UPDATE_FAILURE",

    SERVICES_DELETE_REQUEST: "SERVICES_DELETE_REQUEST",
    SERVICES_DELETE_SUCCESS: "SERVICES_DELETE_SUCCESS",
    SERVICES_DELETE_FAILURE: "SERVICES_DELETE_FAILURE",


};
