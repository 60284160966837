import {themes} from "../themes/themes.mockup";

export const actors = [
    {
        id: 1,
        theme: themes[0],
        name: "Aide juridique",
        type: "public",
        partnership: false,
        pimms: null,
        actions: [
            {id: 1, name: "Aide litige"},
            {id: 2, name: "Médiation"},
        ]
    },
    {
        id: 2,
        theme: themes[0],
        name: "Assurance",
        type: "public",
        partnership: false,
        pimms: null,
        actions: [
            {id: 3, name: "Aide constat"},
            {id: 4, name: "Médiation"},
        ]
    },
    {
        id: 3,
        theme: themes[0],
        name: "Autres",
        type: "public",
        partnership: false,
        pimms: null,
        actions: [
            {id: 5, name: "Médiation"},
        ]
    },
    {
        id: 4,
        theme: themes[0],
        name: "Dossier de naturalisation",
        type: "public",
        partnership: false,
        pimms: null,
        actions: [
            {id: 6, name: "Accompagnement formulaire"},
            {id: 7, name: "Médiation"},
        ]
    },
    {
        id: 5,
        theme: themes[0],
        name: "Mairie",
        type: "public",
        partnership: false,
        pimms: null,
        actions: [
            {id: 8, name: "Accompagnement formulaire"},
            {id: 9, name: "Médiation"},
        ]
    },
    {
        id: 6,
        theme: themes[1],
        name: "Autre",
        type: "prive",
        partnership: false,
        pimms: null
    },
    {
        id: 7,
        theme: themes[1],
        name: "La Poste",
        type: "prive",
        partnership: true,
        pimms: null,
        actions: [
            {id: 10, name: "Accompagnement courrier"},
            {id: 11, name: "Accompagnement banque"},
            {id: 12, name: "Accompagnement téléphonie"},
            {id: 13, name: "Médiation"},
        ]
    },
    {
        id: 8,
        theme: themes[1],
        name: "Opérateur de téléphonie/Internet",
        type: "prive",
        partnership: false,
        pimms: null,
        actions: [
            {id: 14, name: "Aide souscription"},
            {id: 15, name: "Aide litige"},
            {id: 16, name: "Médiation"},
        ]
    },
    {
        id: 9,
        theme: themes[3],
        name: "Autres",
        type: "public",
        partnership: false,
        pimms: null
    },
    {
        id: 10,
        theme: themes[3],
        name: "CV",
        type: "public",
        partnership: false,
        pimms: null,
        actions: [
            {id: 17, name: "Accompagnement CV"},
        ]
    },
    {
        id: 11,
        theme: themes[3],
        name: "Formation",
        type: "public",
        partnership: false,
        pimms: null
    },
    {
        id: 12,
        theme: themes[3],
        name: "Lettre de motivation",
        type: "public",
        partnership: false,
        pimms: null
    },
    {
        id: 13,
        theme: themes[3],
        name: "Mission Locale",
        type: "public",
        partnership: false,
        pimms: null
    },
    {
        id: 14,
        theme: themes[3],
        name: "Pôle Emploi",
        type: "public",
        partnership: false,
        pimms: null,
        actions: [
            {id: 18, name: "Aide litige"},
            {id: 19, name: "Médiation"},
        ]
    },
    {
        id: 15,
        theme: themes[4],
        name: "Autres opérateurs",
        type: "prive",
        partnership: false,
        pimms: null
    },
    {
        id: 16,
        theme: themes[4],
        name: "Chèque énergie",
        type: "prive",
        partnership: false,
        pimms: null
    },
    {
        id: 17,
        theme: themes[4],
        name: "EDF",
        type: "prive",
        partnership: true,
        pimms: null,
        actions: [
            {id: 20, name: "Conseil sur la maîtrise de la consommation d'énergie"},
            {id: 21, name: "Aide litige"},
            {id: 22, name: "Médiation"},
        ]
    },
    {
        id: 18,
        theme: themes[4],
        name: "ENEDIS",
        type: "prive",
        partnership: true,
        pimms: null,
        actions: [
            {id: 23, name: "Conseil sur la maîtrise de la consommation d'énergie"},
            {id: 24, name: "Aide litige"},
            {id: 25, name: "Médiation"},
        ]
    },
    {
        id: 19,
        theme: themes[4],
        name: "ENGIE",
        type: "prive",
        partnership: true,
        pimms: null,
        actions: [
            {id: 26, name: "Conseil sur la maîtrise de la consommation d'énergie"},
            {id: 27, name: "Aide litige"},
            {id: 28, name: "Médiation"},
        ]
    },
    {
        id: 20,
        theme: themes[4],
        name: "GRDF",
        type: "prive",
        partnership: true,
        pimms: null,
        actions: [
            {id: 29, name: "Conseil sur la maîtrise de la consommation d'énergie"},
            {id: 30, name: "Aide litige"},
            {id: 31, name: "Médiation"},
        ]
    },
    {
        id: 21,
        theme: themes[4],
        name: "VEOLIA EAU",
        type: "prive",
        partnership: true,
        pimms: null,
        actions: [
            {id: 32, name: "Conseil sur la maîtrise de la consommation d'énergie"},
            {id: 33, name: "Aide litige"},
            {id: 34, name: "Médiation"},
        ]
    },
    {
        id: 22,
        theme: themes[5],
        name: "Autres",
        type: "public",
        partnership: false,
        pimms: null
    },
    {
        id: 23,
        theme: themes[5],
        name: "Huissiers - recouvrement",
        type: "public",
        partnership: false,
        pimms: null,
        actions: [
            {id: 35, name: "Aide litige"},
            {id: 36, name: "Médiation"},
        ]
    },
    {
        id: 24,
        theme: themes[5],
        name: "Impôts",
        type: "public",
        partnership: false,
        pimms: null,
        actions: [
            {id: 37, name: "Accompagnement formulaire"},
            {id: 38, name: "Aide litige"},
            {id: 39, name: "Médiation"},
        ]
    },
    {
        id: 25,
        theme: themes[5],
        name: "Accompagnement Budgétaire",
        type: "public",
        partnership: true,
        pimms: null
    },
]

export const actor = {
    id: 19,
    theme: themes[4],
    name: "ENGIE",
    type: "prive",
    partnership: true,
    pimms: null,
    actions: [
        {id: 26, name: "Conseil sur la maîtrise de la consommation d'énergie"},
        {id: 27, name: "Aide litige"},
        {id: 28, name: "Médiation"},
    ]
}

export const action = {
    actorId: 19,
    actorName: "ENGIE",
    id: 26,
    name: "Conseil sur la maîtrise de la consommation d'énergie"
}
