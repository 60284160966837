import {createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#23a23e',
        },
        secondary: {
            main: '#e50746',
        },
        tertiary: {
            main: '#1b4f9c'
        }
    },
    typography: {
        fontFamily: [
           "Dosis","BlinkMacSystemFont", "-apple-system", "Segoe UI", "Arial", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial"
        ].join(',')
    }
});

export default theme
