import React from 'react';
import './Footer.css';
import Typography from "@material-ui/core/Typography";

function Footer() {
    return (
      <footer>
          <Typography variant={"body1"}>
              Outil métier UNPIMMS ({new Date().getFullYear()})
          </Typography>
          <Typography variant={"body1"} display={"block"}>
              Réalisé par <a href="https://www.logomotion.fr/">Logomotion</a>
          </Typography>
      </footer>
    );
}

export default Footer;
