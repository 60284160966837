import React from 'react';
import {NavLink} from 'react-router-dom';
import {AppBar, Button, Grid, Hidden, Menu, MenuItem, Toolbar, withWidth} from "@material-ui/core/";
import {Role} from "../../../utils/roles";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faBars,
    faBookOpen, faBriefcase,
    faBuilding,
    faCogs,
    faCommentDots,
    faComments,
    faDatabase,
    faEnvelopeOpenText,
    faLayerGroup,
    faLocationArrow,
    faMapSigns,
    faRoute,
    faSignOutAlt,
    faSuitcase,
    faUsers
} from '@fortawesome/free-solid-svg-icons'
import './Header.css';
import Divider from "@material-ui/core/Divider";
import InfoBar from "./InfoBar";
import {helper} from "../../../modules/helper";
import {useMediaQuery} from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import history from "../../../utils/history";

function Header({...props}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [drawer, setDrawer] = React.useState(false);
    const isMobile = useMediaQuery('(max-width:980px)')
    const currentUser = helper.getCurrentUser();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setDrawer(open)
    };

    function ModuleActivite() {
        return (
          <React.Fragment>
              <Grid item>
                  <NavLink to={"/demarche_rapide"} className={"menuNavLink"}>
                      <Button size={"small"}
                              className="button-nav"
                              startIcon={<FontAwesomeIcon icon={faBriefcase}/>}>
                          Démarches rapides
                      </Button>
                  </NavLink>
              </Grid>
              <Grid item>
                  <NavLink to={"/officeMediation"} className={"menuNavLink"}>
                      <Button size={"small"}
                              className="button-nav"
                              startIcon={<FontAwesomeIcon icon={faEnvelopeOpenText}/>}>
                          Médiation postée
                      </Button>
                  </NavLink>
              </Grid>
              <Grid item>
                  <NavLink to={"/outMediation"} className={"menuNavLink"}>
                      <Button size={"small"} className="button-nav" startIcon={<FontAwesomeIcon icon={faRoute}/>}>
                          Médiation sortante
                      </Button>
                  </NavLink>
              </Grid>
              <Grid item>
                  <NavLink to={"/workshop"} className={"menuNavLink"}>
                      <Button size={"small"} className="button-nav" startIcon={<FontAwesomeIcon icon={faComments}/>}>
                          Ateliers
                      </Button>
                  </NavLink>
              </Grid>
              <Grid item>
                  <NavLink to={"/representation"} className={"menuNavLink"}>
                      <Button size={"small"} className="button-nav" startIcon={<FontAwesomeIcon icon={faCommentDots}/>}>
                          Représentation
                      </Button>
                  </NavLink>
              </Grid>
          </React.Fragment>
        )
    }

    function ModuleAdministration() {
        return (
          <Menu
            id="menu-admin"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
              <MenuItem onClick={handleClose}>
                  <NavLink to={"/offices"} className={"menuNavLink"}>
                      <Button size={"small"}
                              className="button-nav"
                              startIcon={<FontAwesomeIcon icon={faBuilding}/>}>
                          Structures
                      </Button>
                  </NavLink>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                  <NavLink to={"/users"} className={"menuNavLink"}>
                      <Button size={"small"}
                              className="button-nav"
                              startIcon={<FontAwesomeIcon icon={faUsers}/>}>
                          Utilisateurs
                      </Button>
                  </NavLink>
              </MenuItem>
              {props.user.roles.includes(Role.AdminNational) &&
              <React.Fragment>
                  <MenuItem onClick={handleClose}>
                      <NavLink to={"/actors"} className={"menuNavLink"}>
                          <Button size={"small"}
                                  className="button-nav"
                                  startIcon={<FontAwesomeIcon icon={faSuitcase}/>}>
                              Opérateurs
                          </Button>
                      </NavLink>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                      <NavLink to={"/actions"} className={"menuNavLink"}>
                          <Button size={"small"}
                                  className="button-nav"
                                  startIcon={<FontAwesomeIcon icon={faLocationArrow}/>}>
                              Actions d'opérateurs
                          </Button>
                      </NavLink>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                      <NavLink to={"/services"} className={"menuNavLink"}>
                          <Button size={"small"}
                                  className="button-nav"
                                  startIcon={<FontAwesomeIcon icon={faDatabase}/>}>
                              Services
                          </Button>
                      </NavLink>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                      <NavLink to={"/themes"} className={"menuNavLink"}>
                          <Button size={"small"}
                                  className="button-nav"
                                  startIcon={<FontAwesomeIcon icon={faLayerGroup}/>}>
                              Thématiques
                          </Button>
                      </NavLink>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                      <NavLink to={"/orientations"} className={"menuNavLink"}>
                          <Button size={"small"}
                                  className="button-nav"
                                  startIcon={<FontAwesomeIcon icon={faMapSigns}/>}>
                              Orientations
                          </Button>
                      </NavLink>
                  </MenuItem>
              </React.Fragment>
              }
          </Menu>)
    }

    function ModuleAdministrationList() {
        return (
          <div>
              {props.user.roles.includes(Role.AdminNational) &&
              <div>
                  <MenuItem onClick={toggleDrawer(false)}>
                      <NavLink to={"/offices"} className={"menuNavLink"}>
                          <Button size={"small"}
                                  className="button-nav"
                                  startIcon={<FontAwesomeIcon icon={faBuilding}/>}>
                              Structures
                          </Button>
                      </NavLink>
                  </MenuItem>
                  <MenuItem onClick={toggleDrawer(false)}>
                      <NavLink to={"/users"} className={"menuNavLink"}>
                          <Button size={"small"}
                                  className="button-nav"
                                  startIcon={<FontAwesomeIcon icon={faUsers}/>}>
                              Utilisateurs
                          </Button>
                      </NavLink>
                  </MenuItem>
                  <MenuItem onClick={toggleDrawer(false)}>
                      <NavLink to={"/actors"} className={"menuNavLink"}>
                          <Button size={"small"}
                                  className="button-nav"
                                  startIcon={<FontAwesomeIcon icon={faSuitcase}/>}>
                              Opérateurs
                          </Button>
                      </NavLink>
                  </MenuItem>
                  <MenuItem onClick={toggleDrawer(false)}>
                      <NavLink to={"/actions"} className={"menuNavLink"}>
                          <Button size={"small"}
                                  className="button-nav"
                                  startIcon={<FontAwesomeIcon icon={faLocationArrow}/>}>
                              Actions d'opérateurs
                          </Button>
                      </NavLink>
                  </MenuItem>
                  <MenuItem onClick={toggleDrawer(false)}>
                      <NavLink to={"/services"} className={"menuNavLink"}>
                          <Button size={"small"}
                                  className="button-nav"
                                  startIcon={<FontAwesomeIcon icon={faDatabase}/>}>
                              Services
                          </Button>
                      </NavLink>
                  </MenuItem>
                  <MenuItem onClick={toggleDrawer(false)}>
                      <NavLink to={"/themes"} className={"menuNavLink"}>
                          <Button size={"small"}
                                  className="button-nav"
                                  startIcon={<FontAwesomeIcon icon={faLayerGroup}/>}>
                              Thématiques
                          </Button>
                      </NavLink>
                  </MenuItem>
                  <MenuItem onClick={toggleDrawer(false)}>
                      <NavLink to={"/orientations"} className={"menuNavLink"}>
                          <Button size={"small"}
                                  className="button-nav"
                                  startIcon={<FontAwesomeIcon icon={faMapSigns}/>}>
                              Orientations
                          </Button>
                      </NavLink>
                  </MenuItem>
              </div>
              }
              {props.user.roles.includes(Role.AdminLocal) &&
              <div>
                  <MenuItem onClick={toggleDrawer(false)}>
                      <NavLink to={"/offices"} className={"menuNavLink"}>
                          <Button size={"small"}
                                  className="button-nav"
                                  startIcon={<FontAwesomeIcon icon={faBuilding}/>}>
                              Structures
                          </Button>
                      </NavLink>
                  </MenuItem>
                  <MenuItem onClick={toggleDrawer(false)}>
                      <NavLink to={"/users"} className={"menuNavLink"}>
                          <Button size={"small"}
                                  className="button-nav"
                                  startIcon={<FontAwesomeIcon icon={faUsers}/>}>
                              Utilisateurs
                          </Button>
                      </NavLink>
                  </MenuItem>
              </div>
              }
          </div>
        )
    }

    function ModuleActiviteList() {
        return (
          <List>
              <ListItem>
                  <NavLink to={"/demarche_rapide"} className={"menuNavLink"}>
                      <Button size={"small"}
                              className="button-nav"
                              startIcon={<FontAwesomeIcon icon={faBriefcase}/>}>
                          Démarches rapides
                      </Button>
                  </NavLink>
              </ListItem>
              <ListItem>
                  <NavLink to={"/officeMediation"} className={"menuNavLink"}>
                      <Button size={"small"}
                              className="button-nav"
                              onClick={toggleDrawer(false)}
                              startIcon={<FontAwesomeIcon icon={faEnvelopeOpenText}/>}>
                          Médiation postée
                      </Button>
                  </NavLink>
              </ListItem>
              <ListItem>
                  <NavLink to={"/outMediation"} className={"menuNavLink"}>
                      <Button size={"small"} onClick={toggleDrawer(false)} className="button-nav"
                              startIcon={<FontAwesomeIcon icon={faRoute}/>}>
                          Médiation sortante
                      </Button>
                  </NavLink>
              </ListItem>
              <ListItem>
                  <NavLink to={"/workshop"} className={"menuNavLink"}>
                      <Button size={"small"} onClick={toggleDrawer(false)} className="button-nav"
                              startIcon={<FontAwesomeIcon icon={faComments}/>}>
                          Ateliers
                      </Button>
                  </NavLink>
              </ListItem>
              <ListItem>
                  <NavLink to={"/representation"} className={"menuNavLink"}>
                      <Button size={"small"} onClick={toggleDrawer(false)} className="button-nav"
                              startIcon={<FontAwesomeIcon icon={faCommentDots}/>}>
                          Représentation
                      </Button>
                  </NavLink>
              </ListItem>
          </List>
        )
    }

    return (
      <div className="appbar-container">
          {!isMobile && <AppBar color={"default"} position={"fixed"} elevation={0} className="appbar">
              <Toolbar variant={"dense"} disableGutters={true}>
                  <div className={"brand"} onClick={() => history.push("/")}></div>
                  <Grid container>
                      <Grid item container className="menu-container" spacing={1} xs={10} alignItems={"center"}
                            justify="flex-start">
                          {!props.user.roles.includes(Role.AdminNational) &&
                          <ModuleActivite/>
                          }
                          <Grid item>
                              <NavLink to={"/report"} className={"menuNavLink"}>
                                  <Button size={"small"} className="button-nav"
                                          startIcon={<FontAwesomeIcon icon={faBookOpen}/>}>
                                      Rapport d'activité
                                  </Button>
                              </NavLink>
                          </Grid>
                          <Divider orientation="vertical" flexItem className="menu-divider"/>
                          {(props.user.roles.includes(Role.AdminLocal) || props.user.roles.includes(Role.AdminNational)) &&
                          <Grid item>
                              <Button aria-controls="menu-admin" aria-haspopup="true" className="button-nav"
                                      onClick={handleClick}
                                      startIcon={<FontAwesomeIcon icon={faCogs}/>}>
                                  <Hidden mdDown>Administration</Hidden>
                              </Button>
                              <ModuleAdministration/>
                          </Grid>

                          }
                      </Grid>
                      <Grid item container xs={2} justify="flex-end">
                          <Button variant="contained" color="secondary"
                                  startIcon={<FontAwesomeIcon icon={faSignOutAlt}/>} onClick={props.logout}>
                              Se déconnecter
                          </Button>
                      </Grid>
                  </Grid>
              </Toolbar>
          </AppBar>}
          {isMobile &&
          <React.Fragment>
              <Button onClick={toggleDrawer(true)} className="mobile-menu-button">
                  <FontAwesomeIcon icon={faBars}/>
              </Button>
              <Drawer anchor='left' open={drawer} onClose={toggleDrawer(false)}>
                  <div className={"brand"}></div>
                  {!props.user.roles.includes(Role.AdminNational) &&
                  <ModuleActiviteList/>
                  }
                  <MenuItem onClick={handleClose}>
                      <NavLink to={"/report"} className={"menuNavLink"}>
                          <Button size={"small"} className="button-nav"
                                  startIcon={<FontAwesomeIcon icon={faBookOpen}/>}>
                              Rapport d'activité
                          </Button>
                      </NavLink>
                  </MenuItem>
                  {(props.user.roles.includes(Role.AdminLocal) || props.user.roles.includes(Role.AdminNational)) &&
                  <ModuleAdministrationList/>
                  }
                  <MenuItem onClick={handleClose}>
                      <Button variant="contained" color="secondary"
                              startIcon={<FontAwesomeIcon icon={faSignOutAlt}/>} onClick={props.logout}>
                          Se déconnecter
                      </Button>
                  </MenuItem>
              </Drawer>
          </React.Fragment>
          }
          <InfoBar currentUser={currentUser}/>
      </div>
    );
}

export default withWidth()(Header);
