import {isResponsable} from "../utils/roles";

export const helper = {
    parseJwt: (token) => {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    },

    /**
     * Parse data from jwt and store it
     * @param user
     */
    storeJwt: (user) => {
        let data = helper.parseJwt(user.token)
        localStorage.setItem('token', JSON.stringify(user.token));
        localStorage.setItem('refreshToken', JSON.stringify(user.refreshToken));
        localStorage.setItem('user', JSON.stringify(data))
        return data;
    },

    /**
     * Store current user office iri in local storage
     * @param office
     */
    storeUserOffice: (office) => {
        try {
            let user = JSON.parse(localStorage.getItem('user'));
            user.office = office["@id"];
            localStorage.setItem('user', JSON.stringify(user))
        } catch (e) {
            console.error(e);
        }

    },

    /**
     * Store national office IRI in localStorage
     * @param iri
     */
    storeNationalOfficeIri: (iri) => {
        localStorage.setItem('nationalIri', JSON.stringify(iri.iri));
    },

    getNationalOfficeIri: () => {
        try {
            return JSON.parse(localStorage.getItem("nationalIri"))
        } catch (e) {
            return null;
        }
    },

    getUserOfficeIri: () => {
        try {
            let user = JSON.parse(localStorage.getItem('user'));
            return user.office;
        } catch (e) {
            console.error(e);
        }
        return null;
    },

    /**
     * Update user current office
     * Triggered when user change currentOffice
     * @param office
     */
    setNewCurrentOffice: (office) => {
        try {
            let user = JSON.parse(localStorage.getItem('user'));
            user.currentOffice = office;
            localStorage.setItem('user', JSON.stringify(user))
        } catch (e) {
            console.error(e);
        }
    },

    getCurrentUser: () => {
        try {
            return JSON.parse(localStorage.getItem("user"))
        } catch (e) {
            return {}
        }
    },

    normalizeString: (string) => {
        return string.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    },

    /**
     * Check if a user can modify a given data.
     * If national -> yes
     * If responsable -> user can't modify national data.
     * A national data is a data that has national office iri in it's office property.
     * @param data
     */
    canModifyData: (data) => {
        if (isResponsable()) {
            try {
                let nationalId = JSON.parse(localStorage.getItem("nationalIri"))
                if (data.hasOwnProperty("offices")) {
                    if (data.offices.length > 0) {
                        if (data.offices[0].hasOwnProperty('id')) {
                            let iri = nationalId.split("/");
                            for (let o of data.offices) {
                                if (o.id == iri[iri.length-1]) {
                                    return false
                                }
                            }
                            return true
                        } else {
                            return !data.offices.includes(nationalId)
                        }
                    } else {
                        return true;
                    }
                }
            } catch (e) {
                console.error(e);
            }
        }
        return true;
    },

    /**
     * Replace array of object with array IRIs only
     * @param fields: fields of object to process
     * @param data: the data to process
     */
    replaceChildrenWithIRIs: (fields, data) => {
        for (let field of fields) {
            if (data[field] && data[field].length > 0) {
                let arrayOfIris = []
                for (let item of data[field]) {
                    if (item.hasOwnProperty("@id")) {
                        arrayOfIris.push(item['@id'])
                    }
                }
                data[field] = arrayOfIris;
            }
        }
    },

    /**
     * Prepare Office data for api-platform
     * Replace nested entities with IRIs
     * @param values
     * @returns values
     */
    prepareOffice: (values) => {
        helper.replaceChildrenWithIRIs(["operators", "services", "orientations", "children", "users"], values);
        //handle specific case parent, we have to swithc id or @id depending on create/edit to replace with iri
        if (values.hasOwnProperty("parent")) {
            if (values.parent.hasOwnProperty("@id")) {
                values.parent = values.parent["@id"];
            } else if (values.parent.hasOwnProperty("id")) {
                values.parent = values.parent["id"];
            } else {
                values.parent = null
            }
        }
        return values;
    },

    /**
     * Prepare user data for api-platform
     * Replace office entities with Iri
     * @param values
     */
    prepareUser: (values) => {
        if (values.hasOwnProperty("office")) {
            if (values.office.hasOwnProperty("id")) {
                values.office = values.office["id"];
            } else if (values.office.hasOwnProperty("@id")) {
                values.office = values.office["@id"];
            } else {
                values.office = null
            }
        }

        return values;
    },

    /**
     * Prepare operator data for api-platform
     * @param values
     * @returns {{theme}|*}
     */
    prepareOperator: (values) => {
        helper.replaceChildrenWithIRIs(["actions", "theme"], values)
        if (values.hasOwnProperty("theme")) {
            if (values.theme.hasOwnProperty("@id")) {
                values.theme = values.theme["@id"];
            } else if (values.theme.hasOwnProperty("id")) {
                values.theme = values.theme["id"];
            } else {
                values.theme = null
            }
        }

        return values;
    },

    prepareAction: (values) => {
        if (values.hasOwnProperty("operator")) {
            if (values.operator.hasOwnProperty("@id")) {
                values.operator = values.operator["@id"];
            } else if (values.operator.hasOwnProperty("id")) {
                values.operator = values.operator["id"];
            } else {
                values.operator = null
            }
        }

        // for action also add user office id.
        values.offices = [helper.getUserOfficeIri()];

        return values;
    },

    /**
     * When posting a new data (like service, orientation etc..)
     * Append user's office iri to bind the data to the user office
     * @param values
     * @returns {*}
     */
    prepareBeforePost: (values) => {
        let officeIri = helper.getUserOfficeIri();
        if (officeIri) {
            values.offices = [officeIri]
        }
        return values;
    },


    sortAlphabetically: (array, property) => {
        if (array && array.length > 0) {
            if (property) {
                return array.sort((a, b) => {
                    if (a.hasOwnProperty(property) && b.hasOwnProperty(property)) {
                        return (a[property].toLowerCase() < b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() > b[property].toLowerCase()) ? 1 : 0;
                    }
                });
            } else {
                return array.sort((a, b) => {
                    return (a.toLowerCase() < b.toLowerCase()) ? -1 : (a.toLowerCase() > b.toLowerCase()) ? 1 : 0;
                });
            }


        }
        return array;
    }

}
