import {applyMiddleware, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {createLogger} from 'redux-logger';
import rootReducer from '../modules/reducers';
import history from '../utils/history'
import {jwtMiddleware} from '../modules/jwt-middleware';

const loggerMiddleware = createLogger();

const store = createStore(
  rootReducer(history),
  applyMiddleware(
    jwtMiddleware,
    thunkMiddleware,
    loggerMiddleware
  )
);

export default store;
