export function authHeader() {
    // return authorization header with jwt token
    let token = JSON.parse(localStorage.getItem('token'));

    if (token) {
        return 'Bearer ' + token;
    } else {
        return {};
    }
}

export function fetchHeaders() {
    return {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        },
    };
}

export function postHeaders(values) {
    return {
        method: 'POST',
        headers: {
            'Content-Type': 'application/ld+json; charset=utf-8',
            'Authorization': authHeader()
        },
        body: JSON.stringify(values)
    };
}

export function patchHeaders(values) {
    return {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/merge-patch+json',
            'Authorization': authHeader()
        },
        body: JSON.stringify(values)
    };
}

export function deleteHeaders() {
    return {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        },
    };
}
