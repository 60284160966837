import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ loggedIn, role, component: Component, ...rest }) => (
    <Route {...rest} render={props => {
    return (
                   loggedIn
                       ? (checkFirewall(rest.roles, role) ? <Component {...props} /> : <Redirect to={{ pathname: '/home', state: { from: props.location } }} />)
                       : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
               )
    }} />
)

const checkFirewall = (restrictedRoles, role) => {
    if (restrictedRoles.length === 0) return true;

    for (let r of role) {
        if (restrictedRoles.includes(r)) return true;
    }
}

export default PrivateRoute;
