import {authenticationConstants} from './auth.constants';
import {authService} from './auth.service';
import history from '../../utils/history';
import {toast} from 'react-toastify';
import {Role} from "../../utils/roles";
import {officesActions} from "../offices/offices.actions";
import {helper} from "../helper";
import {toastHelper} from "../toast-helper";
import {officesService} from "../offices/offices.service";

export const authActions = {
    login,
    logout,
    askResetPassword,
    resetPassword
};

function login(username, password) {
    return dispatch => {
        dispatch(request({username}));


        authService.login(username, password)
          .then(
            user => {
                if (process.env.REACT_APP_MOCKUP === "true") {
                    dispatch(success(user));
                } else {
                    let data = helper.storeJwt(user);

                    //Get national iri at startup.
                    officesService.fetchNationalOfficeIri().then(iri => {
                        helper.storeNationalOfficeIri(iri)
                    })

                    
                    dispatch(success(data));
                }
                history.push('/home');
            },
            error => {
                toastHelper.handleError(error, "Erreur, identifiants invalides")
                dispatch(failure(error.toString()));
            }
          );
    };

    function request(user) {
        return {type: authenticationConstants.LOGIN_REQUEST, user}
    }

    function success(user) {
        return {type: authenticationConstants.LOGIN_SUCCESS, user}
    }

    function failure(error) {
        return {type: authenticationConstants.LOGIN_FAILURE, error}
    }
}

function logout() {
    authService.logout();
    return {type: authenticationConstants.LOGOUT};
}

/**
 * Forgot password function
 * @param username
 * @returns {function(...[*]=)}
 */
function askResetPassword(username) {
    return dispatch => {
        dispatch(request({username}));

        authService.askResetPassword(username)
          .then(
            user => {
                dispatch(success(user));
                history.push('/home');
            },
            error => {
                dispatch(failure(error.toString()));
            }
          );
    };

    function request(user) {
        return {type: authenticationConstants.ASK_RESET_PASSWORD_REQUEST, user}
    }

    function success(user) {
        toastHelper.displaySuccess("Un email vous a été envoyé pour réinitialiser votre mot de passe!")
        return {type: authenticationConstants.ASK_RESET_PASSWORD_SUCCESS, user}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: authenticationConstants.ASK_RESET_PASSWORD_FAILURE, error}
    }
}

/**
 * Reset password function
 * @param username
 * @returns {function(...[*]=)}
 */
function resetPassword(token, password) {
    return dispatch => {
        dispatch(request());

        authService.resetPassword(token, password)
          .then(
            user => {
                dispatch(success(user));
                history.push('/login');
            },
            error => {
                dispatch(failure(error.toString()));
            }
          );
    };

    function request() {
        return {type: authenticationConstants.RESET_PASSWORD_REQUEST}
    }

    function success() {
        toastHelper.displaySuccess("Votre mot de passe a été correctement réinitialisé")
        return {type: authenticationConstants.RESET_PASSWORD_SUCCESS}
    }

    function failure(error) {
        return {type: authenticationConstants.RESET_PASSWORD_FAILURE, error}
    }
}

